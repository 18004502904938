import React from "react";

type SVGProps = React.SVGAttributes<SVGElement>;

export const Waves: React.FC<SVGProps> = (props) => (
  <svg
    viewBox="0 0 800 129"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M375.93 19.3652C375.93 18 375.93 16.6435 375.887 15.287L364.844 15.5652L364.452 19.7739L375.93 19.3652Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M387.069 19C386.956 17.6783 386.826 16.3913 386.687 15.0522L375.887 15.287C375.887 16.6435 375.887 18.0001 375.93 19.3653C379.634 19.2348 383.356 19.1131 387.069 19Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M398.261 18.6957L397.504 14.8522C393.904 14.9159 390.304 14.9826 386.704 15.0522C386.843 16.3565 386.974 17.6609 387.087 19C390.797 18.8899 394.521 18.7884 398.261 18.6957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M364.452 19.774L364.843 15.5652L354.009 15.8522C353.748 17.2957 353.496 18.7392 353.243 20.2L364.452 19.774Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M402.782 18.5827C405 18.5827 407.209 18.4522 409.426 18.3392C409.052 17.1131 408.678 15.887 408.313 14.6609C406.191 14.7131 404.061 14.7566 401.939 14.7826L397.487 14.8522L398.243 18.6957L402.782 18.5827Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M319.426 21.3131C320.069 19.7653 320.722 18.2088 321.391 16.6609L310.504 16.8522C309.704 18.429 308.913 20.003 308.13 21.574C311.895 21.4957 315.652 21.4175 319.426 21.3131Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M330.626 20.9826C331.136 19.4696 331.664 17.9479 332.209 16.4174L321.391 16.6609C320.722 18.2087 320.07 19.7653 319.426 21.3131C323.16 21.2203 326.893 21.1102 330.626 20.9826Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M353.243 20.1914C353.496 18.7392 353.748 17.2957 354.009 15.8436L343.339 16.1305C342.965 17.6088 342.617 19.0958 342.261 20.5827L353.243 20.1914Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M342.261 20.5913C342.617 19.1043 342.965 17.6174 343.339 16.1391L332.174 16.3913C331.635 17.9218 331.107 19.4435 330.591 20.9565C334.493 20.8522 338.382 20.7304 342.261 20.5913Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M419.791 17.6348C419.391 16.5305 418.983 15.4261 418.583 14.3305C415.168 14.458 411.745 14.5682 408.313 14.6609C408.678 15.887 409.052 17.1131 409.426 18.3392C412.887 18.1739 416.339 17.9305 419.791 17.6348Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M468.991 11.374C469.444 11.9392 469.861 12.5044 470.339 13.0609C473.916 12.4812 477.478 11.9653 481.026 11.5131C480.574 11.1218 480.157 10.7305 479.678 10.3305L474.131 10.7392C472.47 10.9305 470.809 11.1305 469.139 11.3566L468.991 11.374Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M468.991 11.374C468.93 11.2957 468.861 11.2175 468.8 11.1305L458.452 11.9044L458.974 12.774C462.313 12.287 465.652 11.8175 468.991 11.374Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M469.139 11.3565C470.809 11.1304 472.47 10.9304 474.13 10.7391L468.8 11.1305C468.861 11.2174 468.931 11.2957 468.991 11.374L469.139 11.3565Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M479.487 10.1566C477.695 10.3421 475.91 10.5363 474.13 10.7392L479.678 10.3305L479.487 10.1566Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M429.626 16.6522L428.504 13.8609C425.2 14.0348 421.893 14.1913 418.583 14.3305C418.983 15.4261 419.391 16.5305 419.791 17.6348C423.07 17.3566 426.348 17.0174 429.626 16.6522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M310.504 16.8522C306.626 16.916 302.748 16.9595 298.869 16.9827C297.826 18.5827 296.809 20.1653 295.791 21.7479C299.913 21.7131 304.026 21.6551 308.13 21.574C308.913 20.0029 309.704 18.429 310.504 16.8522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M438.956 15.5217L438.087 13.313C434.895 13.513 431.713 13.6956 428.521 13.8609L429.643 16.6521C432.745 16.3043 435.849 15.9275 438.956 15.5217Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M449.026 14.1566C448.765 13.6523 448.513 13.1479 448.261 12.6349C444.861 12.8783 441.469 13.0957 438.069 13.3131L438.939 15.5218C442.307 15.087 445.669 14.632 449.026 14.1566Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M458.974 12.7565L458.452 11.8869C455.061 12.142 451.664 12.3855 448.261 12.6174C448.513 13.1304 448.765 13.6348 449.026 14.1391L458.974 12.7565Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M298.191 40.1044C293.269 40.1044 288.356 39.9855 283.452 39.7478C282.008 41.6 280.504 43.4174 278.904 45.2C284.017 45.5479 289.142 45.7594 294.278 45.8348C295.652 43.9739 296.965 42.0609 298.191 40.1044Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M264.991 76.8262C259.13 76.3479 253.287 75.5566 247.461 74.5392C244.394 77.4136 241.153 80.0969 237.757 82.574C243.89 83.9931 250.092 85.096 256.339 85.8783C259.376 83.0107 262.264 79.9894 264.991 76.8262Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M246.217 93.4087C239.704 92.2174 233.235 90.6086 226.791 88.7565C223.752 89.9497 220.604 90.8438 217.391 91.4261C223.696 93.4348 230.061 95.3392 236.522 96.9392C239.811 96.2837 242.987 95.1499 245.948 93.5739L246.217 93.4087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M308.13 21.6087C304.026 21.6899 299.913 21.7479 295.791 21.7826C294.684 23.5218 293.577 25.2377 292.469 26.9304C296.835 26.9304 301.197 26.8783 305.556 26.7739C306.417 25.0261 307.269 23.3478 308.13 21.6087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M283.852 94.5218C282.617 94.7131 281.365 94.8783 280.113 95.0087C275.23 95.5313 270.316 95.7086 265.409 95.5392C265.156 95.7392 264.913 95.9565 264.661 96.1479C262.071 98.1177 259.166 99.6348 256.069 100.635C261.549 101.308 267.073 101.561 272.591 101.391C273.356 101.391 274.113 101.313 274.878 101.27C277.765 99.8841 280.396 98.0201 282.661 95.7566C283.069 95.3566 283.478 94.9392 283.852 94.5218Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M265.409 95.5218C258.968 95.2865 252.553 94.5744 246.218 93.3914L245.922 93.5566C242.97 95.1362 239.803 96.2758 236.522 96.9392C242.963 98.5782 249.501 99.8125 256.096 100.635C259.192 99.6348 262.097 98.1177 264.687 96.1479C264.913 95.9566 265.157 95.7392 265.409 95.5218Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M332.348 78.287C326.757 80.4783 321.2 82.8088 315.618 85.0349C315.348 85.4609 315.061 85.9044 314.792 86.3131C312.847 89.3821 310.724 92.335 308.435 95.1566C314.644 93.374 320.792 91.3566 326.931 89.2696C328.383 86.1131 329.861 82.974 331.461 79.8957C331.739 79.3479 332.044 78.8175 332.348 78.287Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M347.826 72.8609C344.742 73.8058 341.652 74.8377 338.557 75.9565C336.496 76.7043 334.435 77.487 332.391 78.287C332.087 78.8174 331.783 79.3479 331.522 79.8957C329.922 82.974 328.444 86.1131 326.991 89.2696C332.974 87.2435 338.957 85.1565 344.965 83.1826C345.389 79.9014 346.227 76.6873 347.461 73.6174C347.522 73.4 347.652 73.1304 347.826 72.8609Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M315.652 85.0349C310.583 87.0435 305.487 88.9653 300.304 90.6001L299.435 91.7305C297.285 94.524 294.77 97.0158 291.957 99.1392C297.522 98.0783 303.009 96.7131 308.478 95.1566C310.75 92.3336 312.855 89.3808 314.783 86.3131C315.061 85.887 315.348 85.4609 315.652 85.0349Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M299.417 91.7305L300.286 90.6001C294.923 92.3287 289.435 93.6398 283.869 94.5218C283.469 94.9392 283.086 95.3914 282.686 95.7566C280.422 98.0201 277.79 99.8841 274.904 101.27C280.629 100.945 286.326 100.233 291.956 99.1392C294.763 97.0146 297.273 94.5228 299.417 91.7305Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M256.339 85.8783C250.092 85.0959 243.89 83.993 237.756 82.574C234.397 85.0083 230.771 87.0505 226.948 88.6609L226.791 88.7218C233.235 90.5739 239.704 92.2 246.217 93.374C249.875 91.2834 253.273 88.7674 256.339 85.8783V85.8783Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M301.739 34.0261C297.066 34.0725 292.406 34.029 287.756 33.8957C286.374 35.8696 284.939 37.8261 283.409 39.7478C288.313 39.9797 293.226 40.0986 298.148 40.1044C299.443 38.1305 300.617 36.087 301.739 34.0261Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M367.4 68.0957C360.785 69.2884 354.249 70.8797 347.826 72.8609C347.696 73.1305 347.565 73.4 347.444 73.6696C346.21 76.7395 345.371 79.9536 344.948 83.2348C351.783 80.9913 358.661 78.887 365.661 77.2C365.814 74.4878 366.283 71.8029 367.061 69.2C367.13 68.7913 367.27 68.4435 367.4 68.0957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M305.557 26.7392C301.191 26.8261 296.829 26.8783 292.47 26.8957C290.93 29.2609 289.374 31.5914 287.765 33.8957C292.403 34.0232 297.064 34.0667 301.748 34.0261C303.061 31.6348 304.348 29.2 305.557 26.7392Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M272.104 68.6348C266.522 68.2956 260.957 67.7131 255.4 66.9391C252.791 69.487 250.183 72.0609 247.461 74.5392C253.287 75.5566 259.13 76.3479 264.991 76.8261C267.383 74.1131 269.73 71.3391 272.104 68.6348Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M294.278 45.8348C289.136 45.7652 284.011 45.5536 278.904 45.2C277.374 46.9391 275.756 48.6087 274.035 50.2609C279.298 50.7304 284.571 51.0435 289.852 51.2C291.42 49.4905 292.898 47.6996 294.278 45.8348Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M278.756 61.5044C273.348 61.2522 267.947 60.7914 262.547 60.174C260.147 62.3479 257.774 64.6262 255.4 66.9392C260.956 67.7131 266.521 68.2957 272.104 68.6348C274.278 66.1653 276.469 63.7566 278.756 61.5044Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M490.53 9.56532L490.034 9.25229C486.521 9.49577 483.005 9.78563 479.487 10.1219L479.678 10.2958C483.295 10.0581 486.913 9.8146 490.53 9.56532Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M289.852 51.1739C284.565 51.029 279.293 50.716 274.035 50.2348C272.522 51.687 270.922 53.1217 269.235 54.5218C274.583 55.0522 279.948 55.4348 285.313 55.6261C286.903 54.2218 288.418 52.7358 289.852 51.1739V51.1739Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M283.66 57.0609C284.226 56.6 284.773 56.1304 285.313 55.6522C279.947 55.4609 274.582 55.0783 269.234 54.5479C268.739 54.9565 268.243 55.3652 267.73 55.7652C265.991 57.1565 264.252 58.6348 262.513 60.1739C267.913 60.7913 273.313 61.2522 278.721 61.5044C280.339 59.9392 281.973 58.4435 283.66 57.0609Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M514.643 24.487C516.729 26.1398 518.717 27.9109 520.6 29.7914C526.522 29.8696 532.452 30.1044 538.391 30.4609C536.13 27.9924 533.732 25.6525 531.209 23.4522C524.939 23.0609 518.687 22.8348 512.461 22.8522C513.2 23.3913 513.913 23.9304 514.643 24.487Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M285.061 86.1218C281.582 86.5392 278.104 86.774 274.626 86.8435C271.769 89.9621 268.688 92.8683 265.408 95.5392C270.316 95.7086 275.23 95.5313 280.113 95.0087C281.365 94.8782 282.617 94.713 283.852 94.5217C286.68 91.5273 289.355 88.393 291.869 85.1304C289.617 85.5131 287.347 85.8522 285.061 86.1218Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M274.626 86.8262C277.461 83.7914 280.191 80.5566 282.878 77.261C276.912 77.4672 270.939 77.3162 264.991 76.8088C262.264 79.972 259.376 82.9934 256.339 85.861C262.404 86.6163 268.515 86.9389 274.626 86.8262Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M274.626 86.8262C268.524 86.9376 262.422 86.6151 256.365 85.861C253.301 88.7622 249.903 91.29 246.244 93.3914C252.571 94.5732 258.977 95.2852 265.409 95.5218C268.689 92.851 271.769 89.9448 274.626 86.8262V86.8262Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M306.809 81.8957C304.641 84.7942 302.461 87.6927 300.27 90.5913C305.487 88.9565 310.548 87.0348 315.617 85.0261C317.183 82.6087 318.817 80.2377 320.522 77.913C315.991 79.3478 311.426 80.7391 306.809 81.8957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M291.87 85.1305C289.356 88.393 286.68 91.5274 283.852 94.5218C289.418 93.6398 294.907 92.3287 300.27 90.6001C302.467 87.7015 304.647 84.803 306.809 81.9044C301.905 83.174 296.931 84.2783 291.87 85.1305Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M502.417 8.8262L501.878 8.58274C497.942 8.74505 493.994 8.96824 490.035 9.25229L490.53 9.56531C494.484 9.30445 498.446 9.05808 502.417 8.8262Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M350.687 68.0783C349.583 69.5863 348.612 71.1864 347.782 72.8609C354.219 70.878 360.771 69.2867 367.4 68.0957C368.075 66.2658 368.95 64.5161 370.009 62.8783C363.478 64.2957 357.052 66.1044 350.687 68.0783Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M400.087 59.5739C396.817 59.5304 393.591 59.6434 390.409 59.8608C389.429 61.7453 388.676 63.7388 388.165 65.8C391.881 65.6435 395.629 65.6203 399.409 65.7304L408.809 66.0261C408.958 63.8846 409.295 61.7605 409.817 59.6782L400.087 59.5739Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M335.826 72.913C334.567 74.6374 333.405 76.4316 332.348 78.2869C334.391 77.4869 336.452 76.7043 338.513 75.9565C341.597 74.8377 344.687 73.8058 347.783 72.8609C348.612 71.1864 349.584 69.5863 350.687 68.0783C345.713 69.6174 340.765 71.2609 335.826 72.913Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M370.026 62.8696C368.967 64.5074 368.092 66.2571 367.417 68.087C374.279 66.8521 381.217 66.0851 388.182 65.7913C388.698 63.7357 389.454 61.7481 390.435 59.8696C383.567 60.3659 376.746 61.3686 370.026 62.8696V62.8696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M317.278 26.4609C313.371 26.5711 309.464 26.6638 305.557 26.7392C304.322 29.2001 303.061 31.6349 301.748 34.0262C305.881 33.9914 310.021 33.8784 314.165 33.6871C315.244 31.2957 316.27 28.887 317.278 26.4609Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M308 45.6522C309.16 43.7217 310.241 41.7652 311.244 39.7826C306.89 40.0029 302.542 40.1102 298.2 40.1044C296.974 42.0609 295.678 43.9739 294.287 45.8348C298.849 45.9159 303.42 45.8551 308 45.6522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M314.165 33.687C310.014 33.8783 305.875 33.9914 301.748 34.0262C300.617 36.087 299.443 38.1131 298.191 40.1131C302.533 40.1131 306.881 40.0059 311.235 39.7914C312.269 37.774 313.235 35.7392 314.165 33.687Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M319.426 21.3131C315.661 21.4175 311.896 21.4957 308.122 21.574C307.252 23.3131 306.382 25.0523 305.513 26.7392C309.42 26.6697 313.327 26.5769 317.235 26.461C318 24.7479 318.704 23.0349 319.426 21.3131Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M300.817 55.6697C302.098 54.1798 303.292 52.6609 304.4 51.1131C299.539 51.3218 294.687 51.3392 289.852 51.2001C288.417 52.7619 286.902 54.248 285.313 55.6523C290.434 55.8436 295.652 55.861 300.817 55.6697Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M295.652 61.5566C296.887 60.1044 298.122 58.687 299.374 57.3304C299.878 56.7826 300.374 56.2261 300.852 55.6696C295.679 55.8609 290.505 55.8435 285.348 55.6696C284.809 56.1478 284.261 56.6174 283.696 57.0783C282.009 58.4609 280.374 59.9565 278.792 61.5218C284.348 61.7653 289.992 61.8 295.652 61.5566Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M289.67 68.8C291.652 66.3218 293.635 63.887 295.618 61.5566C289.991 61.8 284.365 61.7653 278.757 61.5044C276.47 63.7566 274.278 66.1652 272.105 68.6348C277.953 68.9942 283.816 69.0494 289.67 68.8V68.8Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M304.4 51.1131C305.696 49.3305 306.887 47.5044 308 45.6522C303.42 45.8435 298.846 45.9044 294.278 45.8348C292.898 47.6996 291.421 49.4905 289.852 51.2C294.687 51.3391 299.539 51.3218 304.4 51.1131Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M282.878 77.2609C285.174 74.4435 287.426 71.5914 289.67 68.7827C283.816 69.032 277.953 68.9769 272.104 68.6175C269.73 71.3218 267.383 74.0957 264.991 76.8087C270.939 77.3162 276.912 77.4672 282.878 77.2609V77.2609Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M320.521 77.9217C318.817 80.2406 317.182 82.6116 315.617 85.0348C321.2 82.8087 326.756 80.4782 332.347 78.2869C333.405 76.4316 334.566 74.6374 335.826 72.913C330.739 74.6521 325.643 76.313 320.521 77.9217Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M504.756 17.9565C511.06 17.7391 517.4 17.8174 523.739 18.1044C521.183 16.671 518.544 15.3933 515.834 14.2783C510.304 14.2783 504.774 14.4 499.261 14.8C501.104 15.8522 502.939 16.8957 504.756 17.9565Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M536.026 48.5653C541.852 48.629 547.687 48.7189 553.531 48.8348C550.922 45.5913 548.374 42.3044 545.757 39.0783C539.948 38.8087 534.139 38.6 528.365 38.4957C530.991 41.774 533.504 45.1653 536.026 48.5653Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M328.895 26.0696L330.635 20.9826C326.895 21.1044 323.162 21.2145 319.435 21.3131C318.713 23.0522 318.009 24.7478 317.287 26.4609C321.159 26.3507 325.029 26.2203 328.895 26.0696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M528.348 38.487C534.139 38.5914 539.948 38.8001 545.739 39.0696C543.365 36.1218 540.939 33.2349 538.374 30.4609C532.435 30.1044 526.504 29.8697 520.583 29.7914C523.331 32.5433 525.923 35.4464 528.348 38.487V38.487Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M388.165 65.8C381.199 66.0938 374.261 66.8608 367.4 68.0957C367.269 68.4435 367.13 68.7913 367.017 69.1565C366.239 71.7594 365.77 74.4444 365.617 77.1566C372.76 75.3795 380.027 74.147 387.356 73.4696C387.356 71.2276 387.553 68.9898 387.947 66.7826C388.008 66.4522 388.095 66.1305 388.165 65.8Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M491.843 10.4174C496 10.0812 500.154 9.82321 504.304 9.64351L502.417 8.82613C498.452 9.05221 494.49 9.29859 490.53 9.56525C490.965 9.8522 491.4 10.1392 491.843 10.4174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M493.713 11.574C498.13 11.1653 502.53 10.887 506.939 10.7044C506.069 10.3479 505.2 9.9914 504.33 9.62619C500.179 9.8001 496.026 10.0581 491.869 10.4001C492.461 10.8088 493.087 11.174 493.713 11.574Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M495.974 12.913C500.791 12.4696 505.6 12.2087 510.417 12.0956L506.939 10.7218C502.53 10.887 498.131 11.1652 493.713 11.5913C494.461 12.0435 495.217 12.4696 495.974 12.913Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M499.261 14.8001C504.774 14.4001 510.305 14.2436 515.835 14.2784C514.035 13.5218 512.226 12.8088 510.418 12.0958C505.6 12.2088 500.791 12.4697 495.974 12.9131C497.07 13.5479 498.165 14.1827 499.261 14.8001Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M508.13 59.6261C510.739 62.8957 513.408 66.0957 516.226 69.1913C517.461 69.1913 518.695 69.1913 519.939 69.2435C524.826 69.2957 529.713 69.2435 534.591 69.2435C531.548 66.0783 528.669 62.8 525.895 59.4348C519.977 59.5044 514.055 59.5681 508.13 59.6261Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M442.261 59.8696C442.878 62.3304 443.574 64.7826 444.4 67.2261L461.939 67.8261C460.542 65.2174 459.183 62.571 457.861 59.887C452.661 59.9043 447.461 59.8986 442.261 59.8696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M457.861 59.913C459.171 62.5913 460.53 65.2376 461.939 67.8522C467.956 68.0551 473.971 68.2406 479.982 68.4087C478.017 65.6087 476.113 62.7565 474.261 59.8782C468.811 59.8724 463.345 59.884 457.861 59.913Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M426.252 59.8174C426.235 62.0938 426.375 64.3687 426.669 66.6261L444.399 67.2522C443.573 64.8087 442.878 62.3565 442.26 59.8957C436.921 59.8783 431.585 59.8522 426.252 59.8174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M474.261 59.8696C476.113 62.7479 478.017 65.6001 479.982 68.4001C485.942 68.574 491.898 68.7276 497.852 68.8609C495.434 65.8812 493.061 62.8523 490.73 59.774L474.261 59.8696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M409.817 59.6783C409.295 61.7605 408.958 63.8846 408.809 66.0261L426.669 66.6261C426.375 64.3687 426.235 62.0938 426.252 59.8174C420.774 59.7826 415.295 59.7362 409.817 59.6783Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M517.191 48.5044C520.052 52.1652 522.922 55.8261 525.887 59.4087C531.98 59.3276 538.067 59.2348 544.148 59.1305C541.304 55.7044 538.635 52.1739 535.982 48.5566C529.727 48.4986 523.464 48.4812 517.191 48.5044Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M534.591 69.2175C540.835 69.1421 547.072 68.9856 553.304 68.7479C550.036 65.769 546.99 62.5546 544.191 59.1305C538.093 59.2348 532.006 59.3276 525.93 59.4087C528.696 62.774 531.548 66.0522 534.591 69.2175Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M490.73 59.7826C493.049 62.855 495.423 65.884 497.852 68.8695C503.974 69.0087 510.098 69.1188 516.226 69.2C513.409 66.0956 510.73 62.8956 508.13 59.6348C502.327 59.6927 496.527 59.742 490.73 59.7826Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M544.191 59.1305C550.162 59.0377 556.136 58.9334 562.113 58.8174C559.095 55.6174 556.278 52.2522 553.53 48.8261C547.687 48.7044 541.852 48.6145 536.026 48.5566C538.678 52.1392 541.348 55.7044 544.191 59.1305Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M504.756 17.9566C507.417 19.5131 510.026 21.1131 512.461 22.8522C518.687 22.8522 524.939 23.0609 531.208 23.4522C530.643 22.9653 530.078 22.487 529.469 22.0175C527.658 20.5579 525.729 19.2492 523.704 18.1044C517.391 17.8175 511.06 17.7392 504.756 17.9566Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M247.461 74.5391C241.548 73.513 235.644 72.2609 229.765 70.8522C226.351 73.3078 222.804 75.5721 219.139 77.6348C225.331 79.4609 231.539 81.1131 237.757 82.574C241.153 80.0969 244.394 77.4136 247.461 74.5391V74.5391Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M332.174 16.3913C332.666 14.9768 333.185 13.5536 333.73 12.1218L323.295 12.2957C322.634 13.7392 321.991 15.1884 321.365 16.6435L332.174 16.3913Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M274.035 50.3044C269.165 49.8696 264.304 49.3131 259.452 48.6696C257.808 50.0696 256.104 51.4348 254.313 52.7653C259.281 53.4783 264.255 54.087 269.235 54.5914C270.921 53.1479 272.521 51.7131 274.035 50.3044Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M267.73 55.7653C268.243 55.3653 268.739 54.9566 269.235 54.5479C264.249 54.0493 259.275 53.4406 254.313 52.7218C253.33 53.4609 252.33 54.1827 251.295 54.8957C249.835 55.8957 248.4 56.9305 246.948 57.974C252.165 58.8435 257.322 59.574 262.522 60.174C264.252 58.6348 265.974 57.1566 267.73 55.7653Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M255.4 66.9392C249.852 66.174 244.321 65.2001 238.8 64.1392C235.817 66.4175 232.817 68.6871 229.765 70.8523C235.643 72.261 241.548 73.5131 247.461 74.5392C250.148 72.0436 252.782 69.4871 255.4 66.9392Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M199.46 85.5479C199.087 85.4174 198.704 85.3043 198.33 85.1826C193.826 86.1217 189.313 86.8174 184.843 87.6522L185.843 87.9043C192.252 89.5739 198.669 91.3826 205.104 93.1913C209.226 92.6609 213.365 92.2087 217.408 91.4522C211.365 89.5044 205.382 87.4783 199.46 85.5479Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M226.956 88.6957C230.78 87.0853 234.406 85.0431 237.765 82.6088C231.548 81.1827 225.339 79.4957 219.148 77.6696C215.542 79.6939 211.758 81.3822 207.843 82.7131C214.156 84.8696 220.478 86.9392 226.835 88.8L226.956 88.6957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M217.391 91.4262C213.348 92.2088 209.209 92.661 205.087 93.1653C211.791 95.0435 218.522 96.8957 225.287 98.5305C229.052 98.0957 232.817 97.6609 236.478 96.9131C230.078 95.3392 223.713 93.4349 217.391 91.4262Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M217.391 91.4261C220.598 90.8428 223.74 89.9487 226.774 88.7566C220.417 86.9218 214.096 84.8522 207.783 82.6696C204.676 83.68 201.515 84.5105 198.313 85.1566C198.687 85.2783 199.069 85.3914 199.443 85.5218C205.383 87.4783 211.365 89.5044 217.391 91.4261Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M292.47 26.8957C288.209 26.8957 283.945 26.8957 279.678 26.8957C277.939 29.0696 276.2 31.2435 274.417 33.3392C278.864 33.6001 283.313 33.7972 287.765 33.9305C289.374 31.5914 290.931 29.2609 292.47 26.8957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M301.782 12.5914C298.136 12.5914 294.49 12.6116 290.843 12.6522C289.684 14.1131 288.524 15.5624 287.365 17C291.197 17 295.032 16.9827 298.869 16.9479C299.82 15.5102 300.791 14.058 301.782 12.5914Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M310.505 16.8522C311.252 15.3914 312.018 13.9421 312.8 12.5044C309.131 12.5566 305.458 12.5972 301.783 12.6261C300.791 14.0928 299.82 15.5566 298.87 17.0174C302.748 16.9711 306.626 16.916 310.505 16.8522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M321.391 16.6609C322.017 15.2059 322.661 13.7566 323.322 12.3131C319.809 12.3711 316.302 12.4204 312.8 12.461C312.017 13.9218 311.252 15.3711 310.504 16.8088L321.391 16.6609Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M298.869 16.9827C295.038 17.0175 291.203 17.0349 287.365 17.0349C286.122 18.6175 284.887 20.2088 283.661 21.7827C287.704 21.7827 291.756 21.7827 295.835 21.7827C296.809 20.1653 297.826 18.5827 298.869 16.9827Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M283.478 39.7479C278.835 39.5305 274.208 39.2262 269.565 38.8349C267.971 40.574 266.319 42.2754 264.608 43.9392C269.374 44.4493 274.145 44.8696 278.921 45.2001C280.504 43.4175 282.008 41.6088 283.478 39.7479Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M287.765 33.8957C283.313 33.7682 278.863 33.5711 274.417 33.3044C272.834 35.174 271.217 37.0262 269.547 38.8349C274.182 39.2262 278.808 39.5305 283.461 39.7479C284.948 37.8261 286.382 35.8696 287.765 33.8957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M278.904 45.2001C274.122 44.8754 269.351 44.4551 264.591 43.9392C262.939 45.5392 261.235 47.1044 259.452 48.6262C264.304 49.2697 269.165 49.8262 274.035 50.261C275.757 48.6088 277.391 46.9218 278.904 45.2001Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M292.469 26.8958C293.583 25.1914 294.69 23.4755 295.791 21.7479C291.756 21.7479 287.704 21.7479 283.617 21.7479C282.287 23.4871 281.009 25.1392 279.635 26.8262C283.936 26.89 288.214 26.9131 292.469 26.8958Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M236.521 96.9392C232.86 97.7305 229.095 98.1218 225.33 98.5565C232.513 100.296 239.747 101.809 247.069 102.852C250.137 102.325 253.165 101.584 256.13 100.635C249.523 99.8139 242.974 98.5796 236.521 96.9392V96.9392Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M262.548 60.174C257.331 59.574 252.157 58.8261 246.974 57.974C244.226 59.9827 241.513 62.0609 238.8 64.1392C244.322 65.2174 249.852 66.1739 255.4 66.9392C257.774 64.6261 260.148 62.3479 262.548 60.174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M542.356 76.7566C545.371 79.5392 548.466 82.258 551.643 84.9131C558.681 84.7508 565.71 84.5247 572.73 84.2348C569.174 81.6262 565.652 78.887 562.2 76.1305C555.591 76.4088 548.976 76.6175 542.356 76.7566Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M527.67 76.9304H523.565C526.337 79.7304 529.154 82.4898 532.018 85.2087H532.566C538.931 85.2087 545.287 85.0782 551.644 84.9304C548.479 82.2811 545.383 79.5623 542.357 76.7739C537.461 76.8609 532.566 76.9304 527.67 76.9304Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M504.348 76.6522C506.754 79.4638 509.197 82.2406 511.678 84.9826C518.455 85.1276 525.235 85.1971 532.017 85.1913C529.154 82.4783 526.336 79.7189 523.565 76.9131C517.131 76.8899 510.725 76.803 504.348 76.6522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M466.087 75.4C467.594 78.0957 469.11 80.7856 470.635 83.4696C477.591 83.8 484.548 84.0899 491.504 84.3392C489.475 81.5972 487.464 78.8464 485.469 76.087C479.023 75.8957 472.562 75.6667 466.087 75.4Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M485.487 76.1217C487.487 78.8754 489.499 81.6261 491.522 84.3739C498.235 84.6348 504.954 84.8377 511.678 84.9826C509.203 82.2348 506.759 79.458 504.348 76.6522C498.064 76.513 491.777 76.3362 485.487 76.1217Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M562.2 76.1305C565.678 78.887 569.157 81.5914 572.73 84.2349C579.861 83.9566 586.985 83.6407 594.104 83.287C590.122 80.6088 586.165 77.8986 582.235 75.1566C575.556 75.5218 568.878 75.8465 562.2 76.1305Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M590.982 66.8261C584.681 67.2087 578.376 67.5681 572.069 67.9043C572.513 68.2435 572.939 68.5826 573.417 68.913C576.33 71.0174 579.278 73.0957 582.234 75.1653C588.936 74.7885 595.64 74.3971 602.347 73.9913L592.443 67.7913C591.947 67.4695 591.469 67.1391 590.982 66.8261Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M592.443 67.7827L602.348 73.9827C609.095 73.5827 615.843 73.1856 622.591 72.7914C618.904 70.687 615.211 68.5885 611.513 66.4958L610.061 65.6262C603.684 66.0088 597.324 66.4001 590.982 66.8001C591.469 67.1392 591.948 67.4697 592.443 67.7827Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M594.104 83.2869C601.24 82.9333 608.376 82.5681 615.513 82.1913C611.13 79.4608 606.742 76.7247 602.347 73.9826C595.64 74.3884 588.936 74.7797 582.234 75.1565C586.159 77.8986 590.116 80.6087 594.104 83.2869Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M408.774 73.2609C408.826 75.7885 408.927 78.3305 409.078 80.887C415.93 81.0609 422.774 81.287 429.608 81.5653C429.035 78.9566 428.487 76.4349 427.982 73.8696C421.582 73.632 415.179 73.429 408.774 73.2609Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M308.434 95.1566C307.182 96.7044 305.913 98.2088 304.608 99.7131C311.243 98.2262 317.843 96.5044 324.434 94.7131C325.304 92.8957 326.095 91.0783 326.93 89.2696C320.791 91.3566 314.643 93.374 308.434 95.1566Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M291.939 99.1392C290.105 100.557 288.192 101.817 286.252 103.044C292.409 102.217 298.522 101.07 304.609 99.7131C305.913 98.2088 307.218 96.7044 308.435 95.1566C302.991 96.7131 297.505 98.0783 291.939 99.1392Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M326.956 89.2696C326.087 91.0783 325.295 92.8957 324.461 94.7131C331.008 92.9305 337.504 91.087 344.095 89.3392C344.356 87.2783 344.617 85.2 344.965 83.1913C338.895 85.1566 332.913 87.2435 326.956 89.2696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M274.887 101.27C272.25 102.516 269.52 103.554 266.721 104.374C273.254 104.377 279.78 103.932 286.252 103.044C288.191 101.817 290.104 100.557 291.939 99.1391C286.309 100.233 280.612 100.944 274.887 101.27V101.27Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M447.113 74.6522C448.116 77.2783 449.13 79.9015 450.157 82.5218L470.635 83.5044C469.116 80.8145 467.6 78.1247 466.087 75.4348L447.113 74.6522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M427.983 73.8696C428.487 76.4349 429.035 79.0001 429.609 81.5653C436.461 81.8436 443.31 82.1508 450.156 82.487C449.125 79.8725 448.11 77.2493 447.113 74.6175C440.742 74.3566 434.365 74.1073 427.983 73.8696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M272.6 101.391C267.081 101.561 261.558 101.308 256.078 100.635C253.113 101.584 250.085 102.325 247.017 102.852C252.283 103.619 257.586 104.104 262.904 104.304C264.182 104.304 265.452 104.365 266.73 104.374C269.529 103.554 272.259 102.516 274.895 101.27C274.121 101.313 273.365 101.365 272.6 101.391Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M365.617 77.1566C365.452 79.4957 365.426 81.8697 365.443 84.2436C372.776 82.7292 380.196 81.675 387.661 81.0871C387.469 78.5392 387.322 75.9914 387.356 73.4696C380.027 74.147 372.76 75.3795 365.617 77.1566Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M365.617 77.1566C358.661 78.8957 351.704 80.9479 344.904 83.1914C344.591 85.2 344.33 87.2783 344.035 89.3392C351.122 87.4522 358.235 85.687 365.408 84.2435C365.426 81.8696 365.452 79.4957 365.617 77.1566Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M516.87 8.22614L516.574 8.14787L513.504 8.26964L516.87 8.22614Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M562.113 58.8175C556.142 58.9276 550.168 59.032 544.191 59.1305C546.99 62.5546 550.036 65.769 553.304 68.7479C559.565 68.516 565.82 68.2262 572.069 67.8784C568.509 65.1346 565.179 62.1042 562.113 58.8175V58.8175Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M545.756 39.0696C550.748 39.2957 555.742 39.5682 560.739 39.887C557.982 37.0609 555.217 34.2349 552.383 31.4783C547.722 31.087 543.052 30.7392 538.391 30.4609C540.956 33.2348 543.383 36.1218 545.756 39.0696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M553.531 48.8261C558.992 48.9305 564.453 49.0522 569.913 49.1914C566.818 46.1305 563.826 43.0088 560.739 39.887C555.748 39.574 550.754 39.3015 545.757 39.0696C548.374 42.2957 550.922 45.5827 553.531 48.8261Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M572.07 67.8957C578.377 67.5653 584.681 67.2058 590.983 66.8174C587.117 64.2658 583.417 61.4718 579.904 58.4523L562.113 58.8175C565.179 62.1102 568.508 65.1464 572.07 67.8957V67.8957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M562.113 58.8174L579.904 58.4522C576.426 55.5044 573.148 52.3653 569.913 49.1913C564.452 49.058 558.991 48.9363 553.53 48.8261C556.278 52.2522 559.13 55.6174 562.113 58.8174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M506.957 10.7218L510.435 12.0956C516.226 11.9565 522.026 12.0348 527.826 12.2696C526.6 11.6609 525.331 11.087 524.009 10.5305C518.31 10.4493 512.626 10.5131 506.957 10.7218Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M523.739 18.1305C528.655 18.3624 533.582 18.7044 538.522 19.1566C536.602 17.6517 534.608 16.2441 532.548 14.9392C526.982 14.5653 521.417 14.3392 515.869 14.3045C518.567 15.4207 521.195 16.6984 523.739 18.1305Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M529.504 22.0174C530.078 22.487 530.643 22.9652 531.243 23.4522C535.817 23.7392 540.391 24.1131 544.974 24.5479C542.808 22.6348 540.678 20.8174 538.521 19.1305C533.6 18.6899 528.672 18.3479 523.739 18.1044C525.764 19.2491 527.692 20.5579 529.504 22.0174V22.0174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M538.391 30.461C543.052 30.7392 547.722 31.0871 552.382 31.4784C550.208 29.3566 548.035 27.261 545.722 25.2349L544.939 24.5479C540.356 24.1131 535.783 23.7392 531.209 23.4523C533.732 25.6525 536.13 27.9924 538.391 30.461V30.461Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M510.435 12.0957C512.243 12.8088 514.052 13.5218 515.852 14.2784C521.4 14.2784 526.965 14.5392 532.53 14.9131C531.017 13.9653 529.452 13.0783 527.826 12.3044C522.009 12.0436 516.209 11.9566 510.435 12.0957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M444.4 67.2523L426.67 66.6262C426.731 67.087 426.8 67.5392 426.879 68.0001C427.218 69.9566 427.592 71.9131 427.983 73.8696C434.36 74.1015 440.736 74.3508 447.113 74.6174C446.383 72.687 445.652 70.7566 444.948 68.8175C444.757 68.2957 444.583 67.774 444.4 67.2523Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M461.939 67.8522L444.4 67.2522C444.582 67.7739 444.756 68.2957 444.948 68.8174C445.652 70.7566 446.382 72.687 447.113 74.6174L466.087 75.4C464.921 73.3044 463.756 71.2087 462.608 69.1044L461.939 67.8522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M480 68.4087C473.983 68.2348 467.968 68.0493 461.957 67.8522L462.626 69.1044C463.774 71.2087 464.939 73.3044 466.104 75.4C472.574 75.6609 479.041 75.9015 485.504 76.1218C484.009 74.0522 482.519 71.9797 481.035 69.9044C480.661 69.4348 480.331 68.9044 480 68.4087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M426.669 66.6262L408.809 66.0262C408.809 66.4349 408.756 66.8349 408.748 67.2436C408.748 69.2349 408.748 71.2436 408.748 73.2609C415.159 73.4233 421.562 73.6262 427.956 73.8697C427.565 71.9131 427.191 69.9566 426.852 68.0001C426.8 67.5392 426.73 67.087 426.669 66.6262Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M554.504 69.7913C557.026 71.9479 559.6 74.0522 562.2 76.1305C568.878 75.8522 575.545 75.5276 582.2 75.1566C579.243 73.087 576.295 71.0087 573.382 68.9044C572.921 68.574 572.513 68.2348 572.034 67.8957C565.785 68.2261 559.53 68.516 553.269 68.7653C553.704 69.0957 554.095 69.4348 554.504 69.7913Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M553.304 68.7478C547.073 68.9739 540.835 69.1304 534.591 69.2174C534.991 69.6261 535.374 70.0435 535.774 70.4435C537.913 72.6 540.122 74.6956 542.357 76.7565C548.971 76.6232 555.586 76.4145 562.2 76.1304C559.591 74.0522 557.026 71.9478 554.504 69.7913C554.096 69.4348 553.704 69.0956 553.304 68.7478Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M534.591 69.2174C529.713 69.2783 524.826 69.3044 519.939 69.2174C518.695 69.2174 517.461 69.2174 516.226 69.1652C516.6 69.5739 516.965 69.9913 517.339 70.3913C519.379 72.5942 521.455 74.7565 523.565 76.8783H527.669C532.565 76.8783 537.461 76.8087 542.356 76.7043C540.121 74.6435 537.913 72.5478 535.774 70.3913C535.374 70.0435 534.991 69.6261 534.591 69.2174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M497.852 68.8696C491.892 68.7362 485.936 68.5826 479.982 68.4087C480.33 68.9044 480.66 69.4087 481.017 69.9044C482.495 71.9797 483.985 74.0522 485.487 76.1218C491.776 76.3305 498.063 76.5073 504.347 76.6522C502.417 74.4029 500.51 72.1276 498.626 69.8261C498.365 69.5131 498.113 69.1913 497.852 68.8696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M516.226 69.2001C510.098 69.1131 503.974 69.003 497.852 68.8697C498.113 69.1914 498.365 69.5131 498.626 69.8262C500.504 72.145 502.411 74.4204 504.348 76.6522C510.759 76.7914 517.165 76.8783 523.565 76.9131C521.461 74.7798 519.385 72.6175 517.339 70.4262C516.965 70.0262 516.6 69.6088 516.226 69.2001Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M474.13 10.7391C475.91 10.5304 477.695 10.3362 479.487 10.1565C478.8 9.53914 478.121 8.92177 477.452 8.2957L467.017 8.82611C467.617 9.60001 468.217 10.3739 468.826 11.1304L474.13 10.7391Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M506.956 10.7218C512.643 10.5073 518.327 10.4551 524.008 10.5653C523.382 10.3131 522.739 10.0696 522.087 9.84355C521.574 9.66094 521.052 9.48702 520.539 9.32181C515.13 9.32181 509.73 9.40876 504.321 9.64354C505.217 10.0088 506.087 10.3653 506.956 10.7218Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M401.939 14.7827C404.061 14.7827 406.191 14.7131 408.313 14.6609C407.948 13.4667 407.591 12.2841 407.244 11.1131C405.235 11.1131 403.218 11.174 401.209 11.1827C399.722 11.1827 398.244 11.1827 396.765 11.1827C397 12.3914 397.244 13.6088 397.487 14.8261L401.939 14.7827Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M438.069 13.3131C437.652 12.2957 437.243 11.287 436.826 10.2783C433.626 10.4174 430.423 10.5392 427.217 10.6435C427.643 11.7218 428.087 12.7913 428.504 13.8609C431.695 13.6957 434.878 13.5131 438.069 13.3131Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M397.487 14.8523C397.244 13.6349 397 12.4175 396.765 11.2088C393.287 11.2088 389.809 11.2378 386.331 11.2958C386.444 12.5393 386.574 13.7914 386.696 15.0523C390.29 14.9769 393.887 14.9103 397.487 14.8523Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M428.504 13.8609C428.078 12.7914 427.634 11.7218 427.217 10.6436C423.93 10.7537 420.643 10.8494 417.356 10.9305L418.582 14.3305C421.892 14.1914 425.2 14.0349 428.504 13.8609Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M354.009 15.8522C354.252 14.4869 354.504 13.1304 354.774 11.7739L344.409 11.9565C344.032 13.3478 343.675 14.742 343.339 16.1391L354.009 15.8522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M386.687 15.0522C386.565 13.7913 386.434 12.5392 386.321 11.2957C382.843 11.3305 379.365 11.3739 375.887 11.4261C375.887 12.7044 375.887 13.9913 375.887 15.287L386.687 15.0522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M364.843 15.5652C364.965 14.2348 365.095 12.9044 365.243 11.5913C361.765 11.6435 358.287 11.7043 354.808 11.7739C354.539 13.1304 354.287 14.487 354.043 15.8522L364.843 15.5652Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M375.887 15.2871C375.887 13.9914 375.887 12.6784 375.887 11.4262L365.278 11.5914C365.13 12.9045 365 14.2001 364.878 15.5653L375.887 15.2871Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M418.583 14.3305L417.391 10.9305C414.023 11.0174 410.652 11.0783 407.278 11.1131C407.626 12.2725 407.983 13.4551 408.348 14.6609C411.751 14.574 415.162 14.4638 418.583 14.3305Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M408.747 67.2436C408.747 66.8349 408.747 66.4349 408.808 66.0262L399.408 65.7305C395.629 65.6204 391.881 65.6436 388.165 65.8001C388.095 66.1306 388.008 66.4523 387.947 66.7827C387.553 68.9899 387.356 71.2276 387.356 73.4697C391.383 73.1083 395.427 72.9661 399.469 73.0436C402.576 73.1074 405.678 73.1798 408.774 73.261C408.739 71.2436 408.695 69.2349 408.747 67.2436Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M501.878 8.56525C500.956 8.15655 500.043 7.69568 499.148 7.26958C495.4 7.42611 491.652 7.58261 487.904 7.76523C488.6 8.26089 489.313 8.75655 490.035 9.23482C493.994 8.95655 497.942 8.73337 501.878 8.56525Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M513.505 8.26962L516.574 8.14787C515 7.70438 513.444 7.27831 511.913 6.83483C507.652 6.95657 503.397 7.10728 499.148 7.287C500.017 7.73918 500.957 8.15656 501.878 8.58266C505.757 8.42613 509.632 8.3218 513.505 8.26962Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M502.417 8.8261L504.304 9.64346C509.713 9.40869 515.113 9.31304 520.521 9.32174C519.295 8.92175 518.078 8.57393 516.869 8.22612L513.504 8.26961C509.811 8.42613 506.116 8.61162 502.417 8.8261Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M468.8 11.174C468.191 10.4175 467.591 9.64357 466.991 8.86966L456.887 9.3914L458.452 11.9479L468.8 11.174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M490.035 9.25228C489.313 8.77401 488.6 8.27836 487.904 7.7827C484.426 7.94502 480.948 8.11603 477.47 8.29575C478.139 8.92183 478.817 9.53921 479.504 10.1566C483.017 9.80298 486.527 9.50155 490.035 9.25228Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M458.452 11.9043L456.887 9.34783L446.852 9.83478L448.261 12.6348C451.663 12.4029 455.06 12.1594 458.452 11.9043Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M448.261 12.6348L446.852 9.8348C443.513 9.99132 440.171 10.1392 436.826 10.2783C437.243 11.287 437.652 12.2957 438.07 13.313C441.47 13.0957 444.861 12.913 448.261 12.6348Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M343.339 16.1391C343.681 14.742 344.038 13.3478 344.409 11.9565L333.765 12.1478C333.226 13.5739 332.707 14.9971 332.209 16.4174L343.339 16.1391Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M362.939 55.0261C360.922 57.0435 358.826 59.0174 356.852 61.0435C362.678 59.5392 368.539 58.1826 374.478 57.0783C375.93 55.4087 377.426 53.7652 378.869 52.0696C373.504 52.9131 368.209 53.9131 362.939 55.0261Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M438.096 41.3218C438.548 43.1392 438.965 44.9566 439.348 46.774C443.243 46.2088 447.122 45.6001 451.009 44.974C450.156 43.0088 449.316 41.0349 448.487 39.0523C445.026 39.8696 441.565 40.6001 438.096 41.3218Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M426.452 43.487C426.565 45.1391 426.6 46.7913 426.574 48.4348C430.843 47.9565 435.104 47.3913 439.348 46.7739C438.956 44.9565 438.548 43.1392 438.095 41.3218C434.235 42.1218 430.356 42.8522 426.452 43.487Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M405.904 45.5392C403.296 45.6087 400.765 45.7479 398.209 45.9218C397.617 47.3778 396.928 48.7925 396.148 50.1566C398.93 49.9826 401.722 49.8696 404.548 49.8435C407.243 49.8435 409.922 49.7392 412.6 49.6001C412.968 48.1177 413.258 46.6171 413.469 45.1044C410.974 45.3131 408.443 45.4609 405.904 45.5392Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M382.356 47.5913C381.269 49.1478 380.078 50.6261 378.878 52.0696C384.582 51.2 390.348 50.5217 396.182 50.1565C396.963 48.7925 397.652 47.3777 398.243 45.9217C392.878 46.287 387.6 46.8696 382.356 47.5913Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M413.478 45.087C413.267 46.5998 412.977 48.1004 412.609 49.5827C417.287 49.3392 421.948 48.9305 426.583 48.4174C426.583 46.774 426.583 45.1218 426.461 43.4697C422.165 44.2175 417.843 44.7392 413.478 45.087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M465.913 23.8348C469.626 22.8174 473.382 21.8869 477.217 21.0956C476.434 20.2261 475.66 19.3565 474.887 18.4434C471.295 19.313 467.756 20.1826 464.243 21.2087L465.913 23.8348Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M472.383 33.7827C477.026 32.9131 481.696 32.0957 486.426 31.4957C484.687 29.4088 482.948 27.3479 481.079 25.3392C476.852 26.061 472.696 26.9653 468.574 27.9479C469.815 29.9247 471.084 31.8697 472.383 33.7827Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M468.574 27.9914C472.696 26.9914 476.852 26.087 481.078 25.3827L480.713 24.974C479.554 23.6928 478.394 22.4058 477.235 21.1131C473.4 21.9044 469.643 22.8522 465.93 23.8522C466.791 25.2261 467.687 26.6087 468.574 27.9914Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M464.104 42.8696C468.452 42.1913 472.8 41.5391 477.148 40.9565C475.53 38.5913 473.93 36.2 472.348 33.8087C468.365 34.5826 464.409 35.4348 460.461 36.3218C461.678 38.5131 462.887 40.7392 464.104 42.8696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M457.6 30.887C458.556 32.7015 459.522 34.5131 460.496 36.3218C464.443 35.4522 468.4 34.5826 472.383 33.8087C471.101 31.8783 469.832 29.9391 468.574 27.9913C464.887 28.8957 461.235 29.8783 457.6 30.887Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M448.487 39.0522C449.316 41.0348 450.156 43.0087 451.008 44.9739C455.356 44.2696 459.704 43.5566 464.104 42.8696C462.887 40.6957 461.678 38.5218 460.495 36.3218C456.521 37.2609 452.487 38.1305 448.487 39.0522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M349.782 36.1739C345.724 36.6145 341.666 37.0493 337.608 37.4782C336.948 39.3652 336.217 41.2174 335.4 43.0174C339.591 42.4869 343.774 41.9304 347.956 41.3739C348.695 39.6783 349.252 37.9391 349.782 36.1739Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M374.243 37.8957C374.634 36.436 374.942 34.9554 375.165 33.4609C370.817 33.887 366.548 34.3305 362.243 34.8087C361.863 36.4374 361.398 38.0453 360.852 39.6261C365.316 39.0232 369.78 38.4464 374.243 37.8957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M345.791 46.1044C341.443 46.8174 337.095 47.5131 332.748 48.1479C331.841 49.6733 330.839 51.14 329.748 52.5392C334.24 51.8261 338.724 51.0696 343.2 50.2696C344.146 48.9344 345.012 47.5435 345.791 46.1044V46.1044Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M360.869 39.6262C361.415 38.0453 361.88 36.4374 362.26 34.8088L349.799 36.174C349.269 37.9131 348.686 39.6522 348.008 41.3914C352.278 40.7914 356.565 40.2001 360.869 39.6262Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M340.913 53.2C341.728 52.2633 342.494 51.2852 343.209 50.2695C338.739 51.0637 334.255 51.8203 329.756 52.5391C329.4 52.9913 329.043 53.4087 328.669 53.8782C327.475 55.2811 326.301 56.6898 325.148 58.1043C329.73 57.3217 334.296 56.4609 338.852 55.5739L340.913 53.2Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M367.678 49.9478C366.2 51.6869 364.591 53.3739 362.939 55.0261C368.208 53.913 373.504 52.8956 378.843 52.0696C380.078 50.6261 381.269 49.1478 382.321 47.5913C377.434 48.2696 372.547 49.0696 367.678 49.9478Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M353.209 52.7131C351.652 54.6261 350.026 56.4783 348.374 58.3131C353.217 57.174 358.07 56.0522 362.939 55.0261C364.591 53.374 366.2 51.6957 367.678 49.9479C362.843 50.8174 358.026 51.7566 353.209 52.7131Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M338.852 55.574C337.043 57.6435 335.243 59.7247 333.452 61.8174C338.435 60.6696 343.4 59.4783 348.374 58.3392C350.026 56.5044 351.652 54.6522 353.209 52.7392C348.426 53.6696 343.643 54.6522 338.852 55.574Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M325.148 58.1305C323.339 60.3392 321.562 62.5624 319.817 64.8C324.383 63.887 328.922 62.887 333.452 61.8435C335.243 59.745 337.043 57.6638 338.852 55.6C334.296 56.4609 329.73 57.3218 325.148 58.1305Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M347.991 41.3739C343.809 41.9305 339.626 42.487 335.435 43.0174C334.643 44.7783 333.748 46.4913 332.757 48.1478C337.104 47.5131 341.452 46.8174 345.8 46.1044C346.613 44.567 347.344 42.988 347.991 41.3739Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M339.434 31.7913L326.487 32.913C325.739 34.913 324.965 36.8956 324.121 38.8522C328.631 38.4348 333.139 37.9884 337.643 37.5131C338.298 35.6058 338.895 33.6985 339.434 31.7913Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M335.434 43.0174C336.252 41.2174 336.982 39.3652 337.643 37.4782C333.145 37.9478 328.637 38.3942 324.121 38.8174C323.252 40.7913 322.382 42.7478 321.382 44.6782C321.721 44.6782 322.069 44.6087 322.417 44.5739C326.765 44.0985 331.104 43.5797 335.434 43.0174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M341.061 25.574C341.469 23.9218 341.869 22.2522 342.261 20.5913C338.382 20.7305 334.504 20.8609 330.626 20.9827L328.887 26.0696C332.948 25.9566 337.008 25.7479 341.061 25.574Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M362.244 34.8088C362.635 33.1653 362.957 31.487 363.235 29.7914L351.183 30.7914C350.765 32.6088 350.313 34.4088 349.783 36.174L362.244 34.8088Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M335.435 43.0175C331.104 43.5972 326.756 44.116 322.391 44.574C322.043 44.574 321.696 44.6349 321.356 44.6783C320.426 46.4783 319.417 48.2609 318.322 50.0088C323.145 49.4754 327.948 48.8551 332.73 48.1479C333.731 46.492 334.634 44.7789 335.435 43.0175V43.0175Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M328.695 53.8783C329.069 53.4348 329.425 53.0088 329.782 52.5392C324.965 53.3044 320.121 54.0001 315.243 54.5653C314.373 55.7247 313.443 56.8841 312.452 58.0435C311.808 58.7827 311.173 59.5392 310.547 60.2957C315.46 59.687 320.33 58.9479 325.173 58.1218C326.315 56.6957 327.489 55.2812 328.695 53.8783Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M325.148 58.1305C320.304 58.9565 315.435 59.6957 310.522 60.3044C308.635 62.5913 306.8 64.9565 305 67.3565C309.983 66.6522 314.913 65.7739 319.783 64.8C321.556 62.5507 323.345 60.3275 325.148 58.1305Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M375.165 33.461C375.4 31.9479 375.565 30.3914 375.678 28.8175C371.533 29.1305 367.386 29.4552 363.235 29.7914C362.957 31.4871 362.635 33.1653 362.243 34.8088C366.548 34.3479 370.852 33.8871 375.165 33.461Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M332.757 48.1479C327.974 48.8552 323.171 49.4755 318.348 50.0088C317.383 51.5479 316.348 53.0697 315.217 54.5653C320.096 54.0001 324.939 53.3045 329.757 52.5392C330.848 51.1401 331.85 49.6734 332.757 48.1479V48.1479Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M443.721 27.3827C447.2 26.3479 450.678 25.2697 454.156 24.2088C453.73 23.3392 453.287 22.5305 452.869 21.6957C449.434 22.6349 446.008 23.574 442.582 24.4783C442.939 25.4436 443.33 26.4175 443.721 27.3827Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M442.556 24.4783C442.156 23.4957 441.765 22.5131 441.373 21.5305C438.347 22.2001 435.286 22.8349 432.295 23.4262C432.713 24.5305 433.165 25.6348 433.539 26.7392C436.547 26.0262 439.553 25.2725 442.556 24.4783Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M452.843 21.6957L451.574 19.2C448.171 19.9884 444.771 20.7652 441.374 21.5304C441.765 22.513 442.156 23.4956 442.556 24.4783C445.982 23.5739 449.408 22.6348 452.843 21.6957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M474.887 18.4435C474.313 17.7826 473.748 17.1305 473.148 16.4696C469.67 17.2522 466.235 18.1131 462.817 19L464.2 21.2087C467.756 20.2174 471.304 19.2783 474.887 18.4435Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M464.243 21.2087L462.861 19C459.504 19.8696 456.174 20.7826 452.843 21.6957C453.278 22.5652 453.713 23.3739 454.13 24.2087C457.487 23.1826 460.869 22.1739 464.243 21.2087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M433.539 26.7392C433.131 25.6348 432.67 24.5305 432.296 23.4261C429.009 24.0696 425.722 24.6609 422.409 25.1653C422.809 26.3913 423.2 27.6175 423.565 28.8435C426.957 28.2348 430.235 27.5131 433.539 26.7392Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M305 67.3305C302.948 70.087 300.921 72.8957 298.913 75.687C303.921 74.8696 308.843 73.8522 313.695 72.7044C315.704 70.0522 317.739 67.4001 319.782 64.774C314.913 65.7479 309.982 66.6261 305 67.3305Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M400 31.374C399.913 30.0175 399.783 28.6523 399.609 27.2783C395.586 27.4986 391.562 27.7363 387.539 27.9914C387.539 29.4523 387.539 30.9044 387.409 32.3392C391.623 31.974 395.82 31.6523 400 31.374Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M387.443 32.3218C387.548 30.9044 387.583 29.4522 387.574 27.9739C383.609 28.229 379.643 28.5189 375.678 28.8435C375.565 30.4174 375.4 31.9739 375.165 33.487C379.252 33.0638 383.345 32.6754 387.443 32.3218Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M423.565 28.8522C423.2 27.6261 422.808 26.4001 422.408 25.174C418.861 25.7189 415.292 26.1653 411.704 26.5131C412.034 27.8349 412.33 29.1218 412.574 30.4696C416.29 30.0638 419.953 29.5247 423.565 28.8522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M405.73 31.0435C408.043 30.9218 410.33 30.7131 412.6 30.4696C412.33 29.1479 412.035 27.8609 411.73 26.5131C409.443 26.7218 407.148 26.9044 404.835 27.0087C403.096 27.087 401.356 27.1913 399.67 27.2783C399.843 28.6522 399.974 30.0174 400.061 31.374C401.93 31.2522 403.835 31.174 405.73 31.0435Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M413.617 37.3914C413.713 38.5827 413.756 39.7566 413.756 40.9218C417.887 40.5044 421.939 39.8696 425.93 39.087C425.739 37.8783 425.504 36.6696 425.243 35.4609C421.443 36.2783 417.574 36.9479 413.617 37.3914Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M425.261 35.4609C425.043 34.4435 424.8 33.4174 424.539 32.4C420.835 33.174 417.078 33.8174 413.235 34.2696C413.383 35.3218 413.504 36.3566 413.591 37.3914C417.574 36.9479 421.443 36.2783 425.261 35.4609Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M407.069 37.9305C409.278 37.8262 411.461 37.6465 413.617 37.3914C413.53 36.3566 413.408 35.3219 413.261 34.2697C411.052 34.5306 408.826 34.7306 406.565 34.8523C404.304 34.974 402.269 35.1219 400.13 35.2784C400.13 36.3305 400.052 37.3653 399.956 38.4001C402.322 38.2088 404.687 38.0523 407.069 37.9305Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M425.948 39.087C429.609 38.3653 433.226 37.5218 436.826 36.6087C436.461 35.3653 436.078 34.1218 435.678 32.887C432.2 33.8348 428.774 34.7131 425.243 35.4957C425.522 36.6696 425.756 37.8783 425.948 39.087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M359.13 43.8782C354.687 44.6174 350.252 45.3739 345.809 46.1044C345.024 47.5439 344.152 48.9349 343.2 50.2696L356.878 47.8C357.719 46.5468 358.472 45.2363 359.13 43.8782Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M360.869 39.6261C356.582 40.2 352.295 40.7913 348.009 41.3653C347.359 42.9795 346.624 44.5585 345.809 46.0957C350.252 45.3653 354.687 44.6087 359.13 43.8696C359.795 42.4915 360.376 41.0744 360.869 39.6261V39.6261Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M372.974 41.6087C373.48 40.3891 373.904 39.137 374.243 37.8608C369.774 38.4058 365.31 38.9855 360.852 39.6C360.359 41.0512 359.778 42.4712 359.113 43.8521C363.73 43.1043 368.348 42.3478 372.974 41.6087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M400 38.4C400.096 37.3653 400.156 36.3305 400.174 35.2783C395.765 35.6 391.374 36 387 36.4522C386.803 37.585 386.541 38.7056 386.217 39.8088C390.748 39.2348 395.339 38.7566 400 38.4Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M386.174 39.8087C386.498 38.7056 386.759 37.585 386.956 36.4522C382.707 36.887 378.469 37.3682 374.243 37.8957C373.904 39.1719 373.48 40.4239 372.974 41.6435C377.368 40.9653 381.768 40.3537 386.174 39.8087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M435.652 32.9131C435.331 31.9305 435.009 30.9566 434.67 29.9826C431.305 30.8522 427.931 31.7218 424.513 32.4261C424.774 33.4435 425.018 34.4696 425.235 35.487C428.774 34.7131 432.235 33.8348 435.652 32.9131Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M513.504 8.26963C509.638 8.3218 505.762 8.42614 501.878 8.58266L502.417 8.82614C506.116 8.61744 509.811 8.43195 513.504 8.26963Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M490.53 9.56524C486.913 9.80871 483.295 10.0638 479.678 10.3304C480.121 10.7304 480.547 11.1218 481.026 11.5131C484.634 11.0696 488.243 10.7218 491.843 10.4174C491.4 10.1392 490.965 9.85219 490.53 9.56524Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M119.478 79.5391C116.704 79.4696 113.73 79.4174 110.73 79.3827C113.664 79.4174 116.58 79.4696 119.478 79.5391Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M372.974 41.6087C368.348 42.313 363.731 43.0696 359.113 43.8435C358.463 45.2008 357.719 46.5112 356.887 47.7652C361.612 46.913 366.333 46.0956 371.052 45.313C371.29 44.9304 371.516 44.5478 371.731 44.1652C372.194 43.3375 372.609 42.484 372.974 41.6087V41.6087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M446.418 34.0349L444.852 30.1914C441.8 31.1218 438.766 32.0349 435.679 32.887C436.079 34.1218 436.461 35.3653 436.826 36.6088C440 35.8001 443.226 34.9305 446.418 34.0349Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M372.974 41.6088C372.609 42.484 372.193 43.3376 371.73 44.1653C371.516 44.5479 371.29 44.9305 371.052 45.3131C375.635 44.5653 380.235 43.8783 384.852 43.287C385.379 42.1625 385.821 40.9998 386.174 39.8088C381.762 40.3479 377.362 40.9479 372.974 41.6088Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M386.174 39.8088C385.821 40.9998 385.379 42.1625 384.852 43.287C389.687 42.6609 394.551 42.1653 399.443 41.8001C399.677 40.6654 399.848 39.5187 399.956 38.3653C395.339 38.7566 390.748 39.2349 386.174 39.8088Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M407.07 37.9305C404.687 38.0522 402.322 38.2087 399.956 38.4C399.848 39.5535 399.677 40.7001 399.443 41.8348C401.913 41.6609 404.391 41.5044 406.887 41.4087C409.2 41.3218 411.487 41.1479 413.757 40.9218C413.757 39.7566 413.713 38.5826 413.617 37.3913C411.461 37.6464 409.278 37.8261 407.07 37.9305Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M434.696 29.9565C434.322 28.8783 433.931 27.8087 433.539 26.7392C430.235 27.5131 426.913 28.2348 423.565 28.8522C423.913 30.0348 424.235 31.2174 424.539 32.4C427.957 31.687 431.305 30.8609 434.696 29.9565Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M443.722 27.3827C443.33 26.4175 442.939 25.4435 442.556 24.4783C439.554 25.2667 436.548 26.0204 433.539 26.7392C433.93 27.8088 434.322 28.8783 434.696 29.9566C437.713 29.1479 440.722 28.2783 443.722 27.3827Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M435.652 32.9131C438.722 32.0435 441.739 31.1739 444.826 30.2174C444.444 29.2783 444.07 28.3478 443.696 27.4087C440.696 28.2783 437.687 29.1478 434.67 30.0174C435.035 30.9305 435.357 31.9044 435.652 32.9131Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M455.513 26.8869L454.13 24.2174C450.652 25.2782 447.174 26.3565 443.695 27.3913C444.069 28.3304 444.443 29.2608 444.826 30.2C448.4 29.1043 451.948 27.9826 455.513 26.8869Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M465.913 23.8348L464.243 21.2261C460.852 22.1913 457.487 23.2001 454.13 24.2261L455.513 26.9044C458.956 25.8261 462.408 24.8 465.913 23.8348Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M386.956 36.4522C387.191 35.0849 387.353 33.7062 387.443 32.3218C383.35 32.6696 379.258 33.0493 375.165 33.4609C374.942 34.9554 374.634 36.436 374.243 37.8957C378.469 37.374 382.707 36.8928 386.956 36.4522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M406.565 34.8522C408.826 34.7304 411.052 34.5304 413.261 34.2696C413.079 33.0087 412.852 31.7391 412.6 30.4696C410.331 30.7131 408.044 30.9218 405.731 31.0435C403.835 31.1391 401.931 31.2522 400.035 31.3739C400.113 32.687 400.148 33.9826 400.131 35.2783C402.27 35.1217 404.418 34.9739 406.565 34.8522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M400.131 35.2782C400.131 33.9913 400.131 32.6695 400.035 31.3739C395.826 31.6463 391.629 31.9623 387.444 32.3217C387.354 33.7061 387.191 35.0848 386.957 36.4521C391.304 36 395.722 35.6 400.131 35.2782Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M457.6 30.8871C461.234 29.8784 464.887 28.8958 468.574 27.9914C467.704 26.6088 466.791 25.2262 465.913 23.8349C462.434 24.8001 458.956 25.8262 455.478 26.8871L457.6 30.8871Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M424.539 32.4001C424.235 31.2175 423.913 30.0349 423.565 28.8523C419.948 29.5247 416.293 30.0639 412.6 30.4697C412.852 31.7393 413.078 33.0088 413.261 34.2697C417.078 33.7827 420.87 33.174 424.539 32.4001Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M384.852 43.3478C380.235 43.9391 375.635 44.6261 371.052 45.3739C370.036 46.9842 368.909 48.5213 367.678 49.9739C372.548 49.1043 377.435 48.2957 382.357 47.6174C383.13 46.5029 383.833 45.3412 384.461 44.1392C384.6 43.8609 384.722 43.5913 384.852 43.3478Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M413.756 40.9217C411.487 41.1478 409.2 41.3217 406.887 41.4087C404.391 41.5044 401.913 41.6609 399.443 41.8348C399.356 42.2522 399.261 42.7044 399.156 43.0783C398.901 44.0452 398.584 44.9949 398.208 45.9217C400.765 45.7478 403.322 45.6087 405.904 45.5391C408.487 45.4696 410.974 45.313 413.478 45.1044C413.582 44.3478 413.661 43.5826 413.704 42.8174C413.739 42.1826 413.756 41.5565 413.756 40.9217Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M399.443 41.8348C394.55 42.1943 389.687 42.6899 384.852 43.3218C384.721 43.5914 384.6 43.8609 384.46 44.1218C383.832 45.3239 383.129 46.4856 382.356 47.6001C387.574 46.8783 392.878 46.2957 398.208 45.9305C398.584 45.0036 398.901 44.0539 399.156 43.087C399.26 42.6696 399.356 42.2522 399.443 41.8348Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M444.852 30.1913L446.417 34.0348C450.148 32.9913 453.861 31.9218 457.6 30.887L455.513 26.887C451.948 27.9826 448.4 29.1044 444.852 30.1913Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M356.887 47.8C356.713 48.0696 356.539 48.3391 356.348 48.6087C355.348 50.0174 354.296 51.3826 353.209 52.713C358.026 51.7565 362.844 50.8174 367.678 49.9478C368.909 48.4952 370.036 46.9581 371.052 45.3478C366.322 46.1246 361.6 46.942 356.887 47.8Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M356.887 47.8L343.209 50.3044C342.495 51.32 341.728 52.2981 340.913 53.2348L338.852 55.6087C343.644 54.6696 348.418 53.7044 353.209 52.7479C354.296 51.4174 355.348 50.0522 356.348 48.6435C356.522 48.3392 356.713 48.0696 356.887 47.8Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M436.826 36.6088C436.826 36.7653 436.922 36.9132 436.965 37.061C437.377 38.4755 437.754 39.8958 438.096 41.3219C441.574 40.6001 445.052 39.8349 448.53 39.0523C447.835 37.3827 447.145 35.7103 446.461 34.0349C443.226 34.9306 440 35.8001 436.826 36.6088Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M425.948 39.087C421.939 39.8697 417.887 40.5044 413.774 40.9218C413.774 41.5566 413.774 42.1827 413.722 42.8175C413.722 43.5827 413.6 44.3479 413.496 45.1044C417.844 44.7392 422.192 44.174 426.47 43.487C426.47 42.7914 426.374 42.1044 426.287 41.4088C426.2 40.7131 426.087 39.8697 425.948 39.087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M448.487 39.0522C452.487 38.1392 456.487 37.2175 460.495 36.3218C459.521 34.5131 458.556 32.7015 457.6 30.887C453.861 31.9218 450.147 32.9914 446.417 34.0348C447.101 35.716 447.791 37.3885 448.487 39.0522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M436.826 36.6087C433.226 37.5217 429.609 38.3478 425.948 39.087C426.069 39.8609 426.182 40.6348 426.269 41.4087C426.356 42.1826 426.408 42.7913 426.452 43.4869C430.356 42.8522 434.278 42.1217 438.095 41.3217C437.748 39.8957 437.371 38.4753 436.965 37.0609C436.922 36.913 436.869 36.7652 436.826 36.6087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M349.783 36.174C350.305 34.4349 350.766 32.6088 351.183 30.7914L339.435 31.7914C338.896 33.716 338.299 35.6117 337.644 37.4783C341.696 37.0552 345.742 36.6204 349.783 36.174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M298.896 75.687C300.922 72.8957 302.948 70.087 304.983 67.3305C303.592 67.5305 302.183 67.7218 300.783 67.8957C297.07 68.3479 293.357 68.6349 289.653 68.7653C287.409 71.574 285.157 74.4261 282.861 77.2435C288.014 77.0641 293.151 76.5704 298.244 75.7653L298.896 75.687Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M499.417 48.7739C493.982 48.913 488.565 49.1739 483.156 49.4957C485.632 52.9739 488.156 56.4029 490.73 59.7826C496.527 59.742 502.325 59.6898 508.122 59.6261C505.278 56.0522 502.53 52.4174 499.782 48.7652L499.417 48.7739Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M468.505 50.5218C470.371 53.6813 472.29 56.7972 474.261 59.8697L490.731 59.774C488.157 56.3943 485.632 52.9653 483.157 49.4871C478.258 49.7885 473.374 50.1334 468.505 50.5218Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M440.626 52.7652C441.13 55.1478 441.661 57.5218 442.261 59.8957C447.478 59.8957 452.678 59.8957 457.861 59.8957C456.533 57.1884 455.24 54.4522 453.982 51.687C449.565 52.0435 445.078 52.4348 440.626 52.7652Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M426.409 53.7132C426.331 55.7479 426.252 57.7827 426.252 59.8001C431.591 59.8407 436.928 59.8668 442.261 59.8784C441.661 57.5045 441.131 55.1305 440.626 52.7479C435.887 53.1218 431.157 53.4523 426.409 53.7132Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M508.13 59.6261C514.049 59.5624 519.971 59.4899 525.896 59.4087C522.922 55.8261 520.052 52.1653 517.2 48.5044C511.403 48.5044 505.606 48.5914 499.809 48.7653C502.539 52.4175 505.287 56.0522 508.13 59.6261Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M509.2 38.5043C511.921 41.8 514.565 45.1478 517.191 48.5043C523.463 48.4695 529.742 48.4869 536.026 48.5565C533.504 45.1652 530.991 41.7739 528.347 38.4869C521.947 38.3652 515.565 38.3565 509.2 38.5043Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M501.869 30.1479C504.417 32.8609 506.843 35.6609 509.2 38.5044C515.565 38.3565 521.948 38.3652 528.33 38.5044C525.911 35.4642 523.324 32.5612 520.582 29.8087C514.33 29.7044 508.087 29.8087 501.869 30.1479Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M496.696 39.0001C495.339 39.0783 493.983 39.174 492.635 39.287C495.052 42.4175 497.417 45.5827 499.791 48.7479C505.588 48.603 511.385 48.516 517.182 48.4871C514.574 45.1305 511.913 41.7827 509.191 38.487C505.023 38.603 500.858 38.774 496.696 39.0001Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M342.278 65.0175C339.992 67.5359 337.839 70.1713 335.826 72.9131C340.765 71.2609 345.713 69.6175 350.687 68.0783C352.554 65.5829 354.606 63.2313 356.826 61.0436C351.957 62.2957 347.122 63.6523 342.278 65.0175Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M313.704 72.7044C311.391 75.7566 309.104 78.7914 306.808 81.8957C311.426 80.6957 315.991 79.3479 320.521 77.9218C322.669 74.974 324.921 72.087 327.226 69.2261C322.747 70.4696 318.26 71.6435 313.704 72.7044Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M298.895 75.687C296.582 78.8783 294.261 82.0522 291.869 85.1305C296.93 84.2609 301.904 83.174 306.808 81.8957C309.104 78.8262 311.391 75.7566 313.704 72.7044C308.826 73.8522 303.904 74.8696 298.895 75.687Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M298.26 75.8C293.168 76.6051 288.031 77.0988 282.878 77.2783C280.191 80.5739 277.461 83.8087 274.626 86.8435C278.104 86.7739 281.582 86.5391 285.06 86.1218C287.347 85.8522 289.617 85.513 291.869 85.1304C294.26 82.0522 296.582 78.8783 298.895 75.687L298.26 75.8Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M327.226 69.2522C324.922 72.087 322.669 74.9739 320.522 77.9478C325.643 76.3391 330.739 74.6348 335.826 72.9392C337.839 70.1974 339.992 67.562 342.278 65.0435C337.269 66.4348 332.269 67.8696 327.226 69.2522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M374.452 57.0782C372.839 58.8996 371.36 60.835 370.026 62.8696C376.746 61.3686 383.567 60.3658 390.435 59.8695C391.304 58.0869 392.382 56.3913 393.443 54.6521C387.07 55.0812 380.728 55.8914 374.452 57.0782Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M402.547 54.3827C399.469 54.3827 396.46 54.4696 393.417 54.6696C392.356 56.4087 391.321 58.0783 390.408 59.887C393.591 59.6696 396.817 59.5566 400.086 59.6L409.817 59.7044C410.26 57.8957 410.782 56.1044 411.304 54.3218C408.391 54.3566 405.469 54.4 402.547 54.3827Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M411.304 54.2957C410.783 56.0783 410.261 57.8696 409.817 59.6783C415.296 59.742 420.774 59.7884 426.252 59.8174C426.252 57.7826 426.33 55.7478 426.409 53.7304C421.383 53.9971 416.348 54.1855 411.304 54.2957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M356.826 61.0435C354.606 63.2313 352.554 65.5829 350.687 68.0784C357.052 66.1044 363.478 64.2957 370.026 62.8609C371.36 60.8264 372.839 58.8909 374.452 57.0696C368.513 58.1827 362.652 59.5392 356.826 61.0435Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M501.869 30.1479C508.086 29.8088 514.33 29.7045 520.599 29.7914C518.717 27.9109 516.728 26.1399 514.643 24.4871C513.93 23.9305 513.199 23.3914 512.46 22.8523C506.692 22.8523 500.959 23.0958 495.26 23.5827C497.573 25.6958 499.756 27.8958 501.869 30.1479Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M453.982 51.7044C455.24 54.4697 456.533 57.2059 457.861 59.9132C463.333 59.9132 468.8 59.9132 474.261 59.9132C472.29 56.8233 470.371 53.7045 468.504 50.5566C463.664 50.916 458.823 51.2986 453.982 51.7044Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M440.156 18.5043L438.956 15.5217C435.843 15.9217 432.733 16.2985 429.626 16.6522C430.078 17.7739 430.495 18.8957 430.965 20.0174C434.034 19.5478 437.095 19 440.156 18.5043Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M421.122 21.3826C420.687 20.1305 420.252 18.887 419.791 17.6349C416.313 17.9305 412.887 18.174 409.426 18.3392C409.826 19.6957 410.226 21.0435 410.609 22.4C414.131 22.1479 417.626 21.8 421.122 21.3826Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M399.008 22.9565C398.773 21.5391 398.513 20.113 398.243 18.6956C394.51 18.7826 390.785 18.8841 387.069 19C387.208 20.4783 387.321 21.9565 387.408 23.4348C391.269 23.2609 395.136 23.1014 399.008 22.9565Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M430.965 20.0174C430.522 18.8957 430.096 17.7739 429.626 16.6522C426.348 17.0174 423.07 17.3565 419.791 17.6348C420.235 18.887 420.661 20.1304 421.122 21.3826C424.432 20.9826 427.713 20.5276 430.965 20.0174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M495.122 23.4522L495.261 23.5827C500.959 23.1073 506.693 22.8638 512.461 22.8522C510.026 21.1131 507.417 19.5131 504.756 17.9566C499.823 18.1247 494.916 18.4899 490.035 19.0522C491.765 20.4957 493.469 21.9566 495.122 23.4522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M352.365 25.087C352.669 23.4609 352.956 21.8349 353.235 20.2088L342.252 20.6088C341.861 22.2696 341.461 23.9392 341.052 25.5913L352.365 25.087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M387.408 23.4348C387.321 21.9565 387.208 20.4783 387.069 19C383.356 19.1131 379.635 19.2348 375.93 19.3653C375.93 20.8957 375.93 22.4261 375.93 23.9478L387.408 23.4348Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M363.956 24.5218C364.139 22.9392 364.295 21.3566 364.452 19.774L353.243 20.1914C352.956 21.8175 352.669 23.4436 352.373 25.0697L363.956 24.5218Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M375.896 23.9479C375.896 22.4261 375.939 20.8957 375.896 19.3653L364.418 19.774C364.261 21.3566 364.104 22.9392 363.922 24.5218L375.896 23.9479Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M403.782 22.7826C406.061 22.7044 408.339 22.5652 410.609 22.4C410.226 21.0435 409.826 19.6957 409.426 18.3391C407.209 18.4522 405 18.5391 402.782 18.5826L398.243 18.6957C398.513 20.1131 398.774 21.5391 399.009 22.9565L403.782 22.7826Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M486.609 16.2087C490.817 15.5826 495.035 15.1131 499.261 14.8C498.165 14.1826 497.07 13.5479 495.974 12.9131C492.101 13.2725 488.229 13.7479 484.357 14.3392C485.104 14.9652 485.852 15.5913 486.609 16.2087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M493.713 11.5739C493.087 11.1913 492.461 10.8087 491.843 10.4174C488.243 10.7218 484.634 11.0696 481.026 11.5131C481.547 11.974 482.069 12.4261 482.6 12.8696C486.316 12.3189 490.02 11.887 493.713 11.5739Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M484.348 14.3391C488.226 13.7594 492.099 13.2841 495.965 12.913C495.209 12.4696 494.452 12.0435 493.704 11.5739C490.017 11.887 486.319 12.3333 482.609 12.913L484.348 14.3391Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M490.035 19.0522C494.91 18.4725 499.818 18.1072 504.757 17.9565C502.939 16.8957 501.105 15.8522 499.261 14.8C495.035 15.1131 490.818 15.5826 486.609 16.2087C487.757 17.1565 488.896 18.1304 490.035 19.0522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M482.609 12.913C482.078 12.4696 481.556 12.0435 481.035 11.5565C477.481 11.9913 473.919 12.5072 470.348 13.1043L471.739 14.7739C475.368 14.0261 478.991 13.4058 482.609 12.913Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M468.608 13.3304L470.348 13.0609C469.895 12.5044 469.478 11.9391 469 11.3739C465.661 11.8174 462.321 12.2435 458.982 12.7565L460.261 14.8174C463.034 14.313 465.817 13.7826 468.608 13.3304Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M460.252 14.8174L458.974 12.7566L449.026 14.1565C449.443 15.0261 449.895 15.8261 450.287 16.6696L460.252 14.8174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M450.287 16.6697C449.869 15.8001 449.417 14.9914 449.026 14.1566C445.669 14.6262 442.313 15.0813 438.956 15.5218L440.156 18.5044C443.53 17.9131 446.907 17.3015 450.287 16.6697Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M460.252 14.8174C460.679 15.5217 461.122 16.2174 461.548 16.913C464.447 16.2638 467.345 15.6348 470.244 15.0261L471.774 14.7304L470.383 13.0609L468.644 13.3305C465.818 13.7826 463.035 14.3131 460.252 14.8174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M339.435 31.7914C340.017 29.7392 340.548 27.661 341.061 25.574C337.009 25.7479 332.948 25.9218 328.887 26.0697C328.104 28.3566 327.313 30.6262 326.478 32.8784L339.435 31.7914Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M472.383 33.7827C473.965 36.174 475.565 38.5653 477.183 40.9305C482.313 40.2522 487.461 39.6783 492.635 39.2783C490.609 36.6696 488.548 34.0609 486.426 31.4522C481.739 32.1218 477.026 32.9131 472.383 33.7827Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M486.426 31.5218C488.548 34.0696 490.608 36.6697 492.635 39.3479C493.982 39.2349 495.339 39.1392 496.695 39.061C500.852 38.8175 505.02 38.6465 509.2 38.5479C506.843 35.7044 504.417 32.9044 501.869 30.1914C497.235 30.4436 492.608 30.8175 488.008 31.3653L486.426 31.5218Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M483.156 49.4957C488.565 49.1739 493.983 48.9131 499.417 48.7739H499.791C497.417 45.6087 495.052 42.4435 492.635 39.313C487.461 39.713 482.313 40.287 477.183 40.9652C479.148 43.829 481.139 46.6725 483.156 49.4957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M481.078 25.3653C482.913 27.374 484.687 29.4349 486.426 31.5218L488.008 31.3218C492.608 30.774 497.235 30.4001 501.869 30.1479C499.756 27.8958 497.574 25.6957 495.261 23.5827C490.782 23.9653 486.321 24.4957 481.895 25.2262L481.078 25.3653Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M474.887 18.4435C475.661 19.3131 476.435 20.1827 477.217 21.0957L477.417 21.0522C481.6 20.1827 485.809 19.5479 490.035 19.0522C488.896 18.0957 487.756 17.1566 486.609 16.2088C482.809 16.7827 479.009 17.487 475.226 18.3566L474.887 18.4435Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M473.191 16.4696C473.748 17.1305 474.313 17.7827 474.93 18.4435L475.269 18.3566C479.052 17.487 482.852 16.7827 486.652 16.2088C485.896 15.5914 485.148 14.9653 484.4 14.3392C480.809 14.9044 477.209 15.574 473.617 16.3827L473.191 16.4696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M477.217 21.0957C478.376 22.3885 479.536 23.6755 480.695 24.9566L481.061 25.3653L481.878 25.2262C486.304 24.4957 490.765 23.9653 495.243 23.5827L495.104 23.4523C493.452 21.9566 491.747 20.4957 490.017 19.0522C485.791 19.5479 481.582 20.2001 477.4 21.0522L477.217 21.0957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M473.574 16.3913C477.165 15.5826 480.765 14.913 484.357 14.3478L482.609 12.9131C478.991 13.4348 475.368 14.0551 471.739 14.7739C472.226 15.3565 472.704 15.9391 473.2 16.5131L473.574 16.3913Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M464.104 42.8696C465.536 45.4435 467.003 47.9942 468.504 50.5217C473.379 50.1391 478.264 49.7971 483.156 49.4957C481.139 46.6783 479.148 43.8319 477.182 40.9565C472.817 41.5391 468.461 42.1913 464.104 42.8696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M451.008 44.974C451.982 47.2233 452.974 49.4667 453.982 51.7044C458.823 51.3044 463.664 50.9102 468.504 50.5218C467.008 47.9885 465.542 45.4378 464.104 42.8697C459.73 43.5566 455.374 44.2696 451.008 44.974Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M305 67.3305C306.8 64.9305 308.635 62.5652 310.522 60.2783C308.635 60.5131 306.748 60.7218 304.844 60.9131C301.765 61.2174 298.696 61.4261 295.617 61.5566C293.635 63.887 291.652 66.3218 289.67 68.8C293.374 68.6348 297.087 68.3479 300.8 67.9305C302.2 67.6957 303.609 67.5305 305 67.3305Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M590.982 66.8262C597.359 66.4378 603.718 66.0465 610.06 65.6523C606.008 63.287 602.156 60.7392 598.443 58.0523L579.904 58.461C583.416 61.4805 587.116 64.2745 590.982 66.8262V66.8262Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M378.844 52.0436C377.4 53.7827 375.904 55.3827 374.452 57.0523C380.72 55.8752 387.054 55.0737 393.417 54.6523C394.357 53.1566 395.287 51.661 396.148 50.1392C390.313 50.5218 384.548 51.174 378.844 52.0436Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M333.452 61.8174C331.348 64.2695 329.269 66.7391 327.226 69.2522C332.269 67.8696 337.269 66.4348 342.278 65.0174C344.287 62.7652 346.339 60.5565 348.365 58.313C343.4 59.4783 338.434 60.6696 333.452 61.8174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M426.574 48.4348C426.574 50.174 426.487 51.9131 426.409 53.7131C431.157 53.4522 435.887 53.1218 440.626 52.7652C440.2 50.7739 439.757 48.7739 439.348 46.7739C435.104 47.3913 430.844 47.9566 426.574 48.4348Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M473.191 16.4696C472.695 15.8957 472.217 15.3131 471.73 14.7304L470.2 15.0261C467.301 15.6058 464.403 16.2348 461.504 16.9131C461.939 17.6087 462.374 18.3044 462.817 19C466.278 18.1305 469.713 17.2609 473.191 16.4696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M439.348 46.774C439.783 48.774 440.217 50.774 440.626 52.7653C445.078 52.4349 449.53 52.0696 453.983 51.7044C452.974 49.4668 451.982 47.2233 451.009 44.974C447.122 45.6001 443.243 46.2088 439.348 46.774Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M412.6 49.6001C412.208 51.174 411.73 52.7305 411.304 54.2957C416.348 54.1914 421.382 53.9972 426.408 53.7131C426.487 51.974 426.556 50.1914 426.574 48.4349C421.939 48.9479 417.278 49.3566 412.6 49.6001Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M404.548 49.8435C401.722 49.8435 398.93 49.9826 396.148 50.1565C395.278 51.6782 394.356 53.1739 393.417 54.6696C396.435 54.4696 399.504 54.3652 402.548 54.3826C405.469 54.3826 408.391 54.3826 411.304 54.2957C411.756 52.7304 412.174 51.1739 412.6 49.6C409.922 49.7391 407.243 49.8261 404.548 49.8435Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M348.374 58.3131C346.339 60.5566 344.287 62.7653 342.287 65.0174C347.13 63.6522 351.965 62.2957 356.835 61.0435C358.835 59.0174 360.93 57.0435 362.922 55.0261C358.069 56.0522 353.217 57.174 348.374 58.3131Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M326.487 32.9131C327.357 30.6609 328.113 28.3914 328.896 26.1044C325.023 26.2493 321.151 26.3797 317.278 26.4957C316.27 28.9218 315.244 31.3305 314.165 33.7218C317.644 33.5566 321.061 33.3566 324.522 33.0696L326.487 32.9131Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M324.121 38.8174C324.991 36.8608 325.739 34.8782 326.487 32.8782L324.521 33.0348C321.043 33.3217 317.608 33.5217 314.165 33.687C313.234 35.7391 312.269 37.7739 311.243 39.7739C314.278 39.6174 317.33 39.4174 320.356 39.1478L324.121 38.8174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M319.818 64.774C317.748 67.3827 315.713 70.0522 313.731 72.7044C318.278 71.6435 322.774 70.4696 327.252 69.2261C329.296 66.7131 331.374 64.2435 333.478 61.7913C328.922 62.8609 324.348 63.8609 319.818 64.774Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M363.235 29.7914C363.513 28.0523 363.748 26.3131 363.957 24.5218L352.365 25.0697C352.009 26.9914 351.617 28.9044 351.183 30.7914L363.235 29.7914Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M351.183 30.7913C351.617 28.9043 352.009 26.9913 352.365 25.0696L341.061 25.5739C340.548 27.6609 340.017 29.7391 339.435 31.7913L351.183 30.7913Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M315.218 54.5653C316.348 53.0696 317.383 51.5479 318.348 50.0088C317.252 50.1305 316.165 50.2609 315.07 50.374C311.505 50.7218 307.948 50.9682 304.4 51.1131C303.287 52.6609 302.093 54.1798 300.818 55.6697C305.513 55.4957 310.218 55.1566 314.931 54.6001L315.218 54.5653Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M324.122 38.8175L320.357 39.1566C317.313 39.4262 314.27 39.6262 311.244 39.7827C310.235 41.7653 309.154 43.7218 308 45.6522C312.446 45.4609 316.907 45.1363 321.383 44.6783C322.374 42.7479 323.278 40.7914 324.122 38.8175Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M452.843 21.6957C456.174 20.7827 459.504 19.8783 462.861 19.0001C462.417 18.3044 461.991 17.6088 461.548 16.9131C458.217 17.661 454.895 18.4349 451.574 19.2001L452.843 21.6957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M318.348 50.0087C319.443 48.2696 320.452 46.4783 321.382 44.6783C316.907 45.1421 312.446 45.4667 308 45.6522C306.887 47.5044 305.695 49.3305 304.4 51.1131C307.948 50.9624 311.504 50.716 315.069 50.3739C316.165 50.3044 317.252 50.1305 318.348 50.0087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M310.521 60.2783C311.147 59.5218 311.782 58.7652 312.426 58.0261C313.417 56.8667 314.347 55.7073 315.217 54.5479H314.93C310.217 55.1044 305.513 55.4174 300.817 55.6174C300.339 56.1739 299.843 56.7304 299.339 57.2783C298.087 58.6348 296.852 60.0522 295.617 61.5044C298.695 61.3739 301.765 61.1652 304.843 60.8609C306.747 60.7392 308.634 60.5131 310.521 60.2783Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M432.296 23.4261C431.861 22.287 431.426 21.1566 430.965 20.0174C427.696 20.5218 424.415 20.9768 421.122 21.3826C421.565 22.6435 421.991 23.9044 422.409 25.1739C425.722 24.6609 429.009 24.0696 432.296 23.4261Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M441.374 21.5305L440.156 18.5044C437.095 19.0348 434.069 19.5479 430.965 20.0175C431.417 21.1566 431.835 22.287 432.295 23.4261C435.33 22.8348 438.348 22.2001 441.374 21.5305Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M451.574 19.2001C451.148 18.3305 450.704 17.5131 450.287 16.6696C446.913 17.2957 443.536 17.9073 440.156 18.5044L441.374 21.5305C444.771 20.7769 448.171 20.0001 451.574 19.2001Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M375.652 28.8174C375.774 27.2174 375.834 25.5913 375.869 23.9478L363.93 24.5217C363.721 26.2609 363.487 28.0522 363.208 29.7913C367.377 29.4493 371.524 29.1246 375.652 28.8174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M461.548 16.9131C461.113 16.2174 460.678 15.5218 460.252 14.8174L450.287 16.6696C450.722 17.5391 451.156 18.3565 451.574 19.2C454.896 18.4348 458.261 17.6609 461.548 16.9131Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M387.574 27.9914C387.574 26.487 387.505 24.9566 387.409 23.4349L375.896 23.9479C375.896 25.5914 375.8 27.2175 375.679 28.8175C379.644 28.5218 383.609 28.2465 387.574 27.9914Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M399.644 27.2783C399.47 25.8436 399.252 24.4001 399.009 22.9305C395.142 23.0812 391.275 23.2407 387.409 23.4088C387.504 24.9305 387.565 26.4609 387.574 27.9653C391.591 27.7218 395.615 27.4928 399.644 27.2783Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M422.409 25.174C421.992 23.9044 421.539 22.6435 421.122 21.3827C417.644 21.8001 414.166 22.1479 410.609 22.4001C410.992 23.774 411.366 25.1392 411.705 26.5131C415.299 26.1769 418.867 25.7305 422.409 25.174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M404.809 27.0088C407.122 26.9044 409.417 26.7218 411.704 26.5131C411.365 25.1392 410.991 23.774 410.609 22.4001C408.339 22.5653 406.061 22.7044 403.783 22.7827L399.009 22.9566C399.252 24.4001 399.47 25.8436 399.644 27.3044C401.365 27.1914 403.087 27.087 404.809 27.0088Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M399.469 73.0435C395.427 72.966 391.383 73.1083 387.356 73.4696C387.356 75.9914 387.469 78.5392 387.66 81.0871C391.652 80.7914 395.669 80.6436 399.721 80.6957C402.84 80.7479 405.959 80.8117 409.078 80.887C408.927 78.3305 408.826 75.7885 408.773 73.2609C405.678 73.174 402.576 73.1015 399.469 73.0435Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M215.469 51.8261C210.165 50.687 204.878 49.5305 199.6 48.4C195.895 49.7739 192.182 51.1247 188.461 52.4522C194.035 53.7566 199.617 55.1305 205.208 56.4957C208.626 54.9827 212.035 53.4174 215.469 51.8261Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M205.217 56.4957C199.626 55.1305 194.043 53.7566 188.469 52.4522C184.51 53.8493 180.547 55.2406 176.582 56.6261C182.391 58.087 188.208 59.6609 194.034 61.2783C197.779 59.7595 201.507 58.1653 205.217 56.4957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M286.739 5.50443C287.614 4.61747 288.51 3.73339 289.426 2.85222H279.652C278.608 3.72179 277.591 4.59137 276.582 5.52181L286.739 5.50443Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M221.417 49.0435L224.522 47.5914C219.49 46.6232 214.464 45.6406 209.443 44.6435C206.165 45.9305 202.887 47.174 199.6 48.4C204.878 49.5305 210.165 50.687 215.47 51.8261C217.446 50.9102 219.429 49.9826 221.417 49.0435Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M194.026 61.2784C188.2 59.661 182.382 58.0871 176.574 56.6262L164.252 60.9132C170.139 62.4001 176.052 64.1045 181.965 65.9218C186.087 64.4523 190.052 62.9045 194.026 61.2784Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M156.522 74.2783C149.835 73.0696 143.087 72.1392 136.191 71.5479C130.565 74.0261 124.974 76.7218 119.444 79.5392C126.661 79.7218 133.8 80.0609 140.904 80.6087C146.035 78.2957 151.241 76.1856 156.522 74.2783Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M124.835 88.8523C130.122 85.8262 135.49 83.0783 140.939 80.6088C133.835 80.0609 126.696 79.7392 119.478 79.5392C113.617 82.5392 107.835 85.6784 102.191 88.8697C109.739 88.7914 117.287 88.748 124.835 88.8523Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M120.174 91.6087C121.722 90.6609 123.278 89.7478 124.835 88.8522C117.287 88.7478 109.739 88.7913 102.226 88.8522C99.403 90.4406 96.6146 92.0377 93.8609 93.6435C89.0696 96.4522 84.2957 99.1305 79.4522 101.739C88.0522 101.826 96.6696 101.878 105.287 101.983C110.139 98.4609 114.948 94.8087 120.174 91.6087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M181.965 65.9566C176.052 64.1392 170.139 62.4783 164.252 60.9479C159.956 62.4435 155.663 63.9653 151.374 65.5131C157.461 66.7044 163.6 68.2522 169.634 70.0087C173.861 68.7218 177.948 67.3566 181.965 65.9566Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M169.565 70.0087H169.67C163.583 68.2696 157.496 66.7044 151.409 65.5131L150.678 65.774C145.87 67.5131 141.052 69.4783 136.261 71.5826C143.157 72.1739 149.904 73.1044 156.591 74.313C160.829 72.7594 165.154 71.3247 169.565 70.0087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M238.626 40.4784C233.536 39.69 228.446 38.8639 223.357 38.0001C221.27 39.2001 219.122 40.3392 216.896 41.4175C221.835 42.3392 226.774 43.2697 231.722 44.1653C234.087 42.9914 236.383 41.7827 238.626 40.4784Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M269.234 12.5826C265.275 12.5362 261.313 12.4783 257.347 12.4087C255.695 13.7537 254.058 15.1073 252.434 16.4696C256.669 16.6174 260.904 16.7218 265.139 16.8087C266.495 15.371 267.86 13.9623 269.234 12.5826Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M273.043 8.8C269.339 8.8 265.635 8.77682 261.93 8.73045C260.383 9.94784 258.852 11.1739 257.339 12.4087C261.304 12.4841 265.267 12.542 269.226 12.5826C270.487 11.3131 271.765 10.0522 273.043 8.8Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M276.582 5.5131H266.147C264.721 6.58267 263.321 7.66964 261.939 8.7566C265.643 8.79138 269.347 8.81456 273.052 8.82615C274.205 7.69571 275.382 6.59137 276.582 5.5131Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M279.652 2.84351H269.809C268.568 3.73047 267.348 4.62321 266.148 5.52176H276.582C277.591 4.60872 278.609 3.72177 279.652 2.84351Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M245.165 36.2C240.018 35.5334 234.864 34.8261 229.705 34.0783C227.644 35.4435 225.539 36.7478 223.357 38C228.452 38.8522 233.542 39.6783 238.626 40.4783C240.881 39.1695 243.063 37.7414 245.165 36.2V36.2Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M265.139 16.8087C260.904 16.7218 256.669 16.6174 252.435 16.4696L247.217 20.8174C251.756 21.0609 256.29 21.2551 260.817 21.4L265.139 16.8087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M251.574 30.8C253.157 29.2637 254.707 27.7159 256.226 26.1565C251.403 25.9188 246.568 25.629 241.722 25.2869C239.783 26.8261 237.818 28.3391 235.826 29.8087C240.861 30.3913 245.887 30.9478 250.913 31.4435L251.574 30.8Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M250.913 31.4174C245.887 30.9218 240.861 30.3652 235.826 29.7826C233.826 31.2609 231.791 32.6957 229.739 34.0783C234.893 34.8203 240.046 35.5276 245.2 36.2C247.196 34.7187 249.104 33.1218 250.913 31.4174V31.4174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M260.817 21.374C256.29 21.2291 251.756 21.0349 247.217 20.7914C245.391 22.2957 243.565 23.7914 241.721 25.261C246.568 25.6262 251.403 25.916 256.226 26.1305C257.774 24.5653 259.295 22.974 260.817 21.374Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M231.722 44.1653C226.774 43.2957 221.835 42.3392 216.896 41.4174C214.762 42.4551 212.551 43.4261 210.261 44.3305L209.443 44.6435C214.458 45.6348 219.484 46.6174 224.522 47.5913C226.957 46.4348 229.391 45.3131 231.722 44.1653Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M399.93 101.765C395.974 101.817 392.026 101.974 388.087 102.183C387.895 106.53 387.591 110.791 387.217 115.096C391.443 115.003 395.666 114.945 399.887 114.922H412.434C411.947 110.574 411.426 106.165 410.939 101.757C407.281 101.722 403.611 101.725 399.93 101.765Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M364.087 104.27C363.417 108.209 362.557 112.096 361.617 115.974C370.157 115.586 378.699 115.296 387.244 115.104C387.591 110.757 387.896 106.496 388.113 102.191C380.061 102.617 372.061 103.348 364.087 104.27Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M410.965 101.713C411.452 106.122 411.974 110.513 412.461 114.878L438.009 114.8C436.896 110.568 435.78 106.322 434.661 102.061C426.765 101.852 418.861 101.722 410.965 101.713Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M340.078 107.426C338.791 110.835 337.304 114.061 335.783 117.322C344.391 116.835 353 116.365 361.617 115.974C362.556 112.087 363.417 108.209 364.087 104.27C356.069 105.183 348.069 106.278 340.078 107.426Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M434.661 102.061C435.774 106.316 436.89 110.562 438.009 114.8H463.461L458.47 102.878C450.533 102.548 442.597 102.276 434.661 102.061Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M482.417 103.809L488.809 114.731H514.217L506.713 104.739C498.617 104.461 490.522 104.139 482.417 103.809Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M506.713 104.722L514.217 114.713H539.486C536.524 111.56 533.55 108.409 530.565 105.261C522.617 105.145 514.666 104.965 506.713 104.722Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M458.469 102.835L463.478 114.757C471.924 114.757 480.374 114.757 488.826 114.757L482.435 103.835C474.435 103.487 466.452 103.148 458.469 102.835Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M316.086 110.87C314.208 113.652 312.156 116.2 310.113 118.765C318.669 118.304 327.226 117.8 335.782 117.322C337.304 114.061 338.791 110.835 340.078 107.426C332.078 108.565 324.086 109.757 316.086 110.87Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M156.835 104.07C155.043 103.922 153.243 103.791 151.443 103.661C147.67 106.957 143.913 110.313 139.513 112.983C137.252 114.357 134.93 115.591 132.556 116.835L154.661 117.548C160.948 113.652 167.235 109.774 173.478 105.826C167.956 105.13 162.409 104.522 156.835 104.07Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M151.443 103.661C143.852 103.122 136.234 102.791 128.6 102.513C124.927 105.964 120.892 109.008 116.565 111.591C113.739 113.191 110.843 114.661 107.869 116.07L132.539 116.835C134.887 115.626 137.208 114.357 139.495 112.983C143.913 110.304 147.669 106.957 151.443 103.661Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M105.217 102C96.6001 101.896 87.9827 101.843 79.3827 101.756C71.2435 106.174 63.087 110.356 54.8522 114.487L82.374 115.296C84.7914 114.183 87.2087 113.043 89.6001 111.817C95.0741 108.992 100.299 105.708 105.217 102V102Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M128.6 102.478C120.826 102.217 113.043 102.07 105.252 101.965C100.323 105.69 95.0871 108.989 89.5995 111.826C87.2082 113.009 84.7908 114.148 82.3734 115.304L107.878 116.078C110.808 114.67 113.704 113.2 116.573 111.6C120.899 109.002 124.932 105.944 128.6 102.478V102.478Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M197.948 109.296C189.808 108.07 181.669 106.852 173.504 105.817C167.261 109.765 160.974 113.643 154.687 117.539L182.782 118.487C184.522 117.617 186.339 116.748 188.208 115.748C191.611 113.843 194.867 111.687 197.948 109.296Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M291.47 113.87C289 115.983 286.426 117.948 283.939 119.956C292.635 119.626 301.391 119.2 310.113 118.73C312.157 116.122 314.209 113.617 316.087 110.835C307.887 112.043 299.687 113.052 291.47 113.87Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M267.617 115.461C264.6 117.252 261.391 118.861 258.322 120.6C266.869 120.531 275.409 120.304 283.939 119.991C286.426 117.948 289 115.983 291.469 113.904C283.539 114.652 275.6 115.226 267.617 115.461Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M197.948 109.296C194.868 111.69 191.612 113.849 188.209 115.757C186.34 116.739 184.54 117.635 182.783 118.496C190.899 118.774 198.992 119.064 207.061 119.365C211.279 117 215.592 114.739 219.809 112.504C212.513 111.504 205.218 110.4 197.948 109.296Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M244.147 115.07C241.929 116.805 239.509 118.265 236.939 119.417C236.179 119.742 235.432 120.032 234.695 120.287C242.585 120.53 250.461 120.617 258.321 120.548C261.391 118.809 264.6 117.2 267.617 115.409C259.834 115.696 252.008 115.6 244.147 115.07Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M244.148 115.07C243.026 114.991 241.896 114.922 240.765 114.826C233.757 114.244 226.774 113.417 219.809 112.487C215.591 114.722 211.278 116.983 207.061 119.348L231.226 120.217L234.704 120.33C235.446 120.064 236.194 119.774 236.948 119.461C239.517 118.294 241.934 116.819 244.148 115.07V115.07Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M453.67 4.0435L452.6 2.30437L443.035 2.34786L443.956 4.16525L453.67 4.0435Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M533.913 6.43481C536.371 6.43481 538.834 6.43481 541.304 6.43481L538.052 5.13047L529.547 5.06092C530.965 5.54787 532.417 5.95655 533.913 6.43481Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M529.513 5.08698L538.017 5.15653L534.721 3.82608L525.547 3.78261C526.869 4.23479 528.191 4.66958 529.513 5.08698Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M537.896 7.6957C540.061 7.6957 542.243 7.6957 544.4 7.6957L541.269 6.45224C538.8 6.45224 536.336 6.45224 533.878 6.45224L537.896 7.6957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M540.695 8.56525C542.869 8.63481 545.043 8.72178 547.217 8.81743L544.4 7.69569C542.226 7.69569 540.052 7.65221 537.895 7.69569C538.826 7.96525 539.765 8.2609 540.695 8.56525Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M525.548 3.78269C524.139 3.29572 522.739 2.79137 521.339 2.23483H510.852C511.974 2.81745 513.104 3.33052 514.252 3.79139L525.548 3.78269Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M296.817 5.48696C297.582 4.61739 298.347 3.74781 299.139 2.87824H289.426C288.516 3.75361 287.62 4.63769 286.739 5.53045L296.817 5.48696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M503.722 3.77397C502.764 3.29962 501.832 2.77422 500.93 2.20004H491.069C491.765 2.75657 492.487 3.2957 493.217 3.80006L503.722 3.77397Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M514.252 3.77399C513.104 3.31311 511.974 2.80008 510.852 2.21746H500.93C501.832 2.79165 502.764 3.31705 503.722 3.7914L514.252 3.77399Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M544.704 9.98268C546.783 10.087 548.87 10.2001 550.948 10.3218C549.713 9.81745 548.461 9.32179 547.217 8.82613C545.035 8.73048 542.869 8.64354 540.696 8.57397C542.035 9.02036 543.371 9.48992 544.704 9.98268Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M554.017 14.1479L562.069 14.9566C559.948 14.0348 557.817 13.1334 555.678 12.2522C553.469 12.0696 551.252 11.9044 549.052 11.7479C550.733 12.4841 552.388 13.2841 554.017 14.1479Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M547.887 11.2348L549.052 11.7478C551.252 11.9044 553.47 12.0696 555.678 12.2522C554.113 11.6 552.531 10.9565 550.948 10.3218C548.87 10.2 546.783 10.087 544.704 9.98264C545.774 10.3826 546.835 10.7913 547.887 11.2348Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M605.505 50.0348C611.383 50.1566 617.258 50.2464 623.131 50.3044C619.212 47.9856 615.316 45.6174 611.444 43.2C605.809 42.8986 600.165 42.5508 594.513 42.1566C598.165 44.8348 601.809 47.4696 605.505 50.0348Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M616.913 57.5913C622.971 57.4464 629.029 57.2899 635.087 57.1217C631.029 54.913 627.043 52.6406 623.13 50.3043C617.252 50.2406 611.377 50.1507 605.504 50.0348C609.244 52.6348 613.043 55.1652 616.913 57.5913Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M579.278 30.9914C580.947 32.1914 582.6 33.4001 584.243 34.6001C589.634 35.1653 595.017 35.7131 600.373 36.2088C597.121 34.1276 593.86 32.0348 590.591 29.9305C585.489 29.3218 580.356 28.687 575.191 28.0262L579.278 30.9914Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M594.513 42.1565C600.165 42.5391 605.808 42.887 611.443 43.2C607.75 40.8812 604.061 38.5623 600.374 36.2435C595.017 35.7478 589.635 35.2 584.243 34.6348C587.695 37.1305 591.104 39.6609 594.513 42.1565Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M493.217 3.80006C492.487 3.29571 491.765 2.75657 491.069 2.20004H481.304C481.852 2.75657 482.426 3.30441 483.043 3.83485L493.217 3.80006Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M560 17.5565L570.669 18.8522C567.826 17.487 564.965 16.2 562.095 14.9565L554.043 14.1479C556.043 15.2203 558.029 16.3565 560 17.5565Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M575.191 28.0261C580.345 28.6812 585.478 29.316 590.591 29.9305L590.244 29.7044C587.191 27.7392 584.157 25.9392 581.096 24.2435L567.409 22.5044C570.035 24.2783 572.626 26.1566 575.191 28.0261Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M567.409 22.4783L581.095 24.2174C577.617 22.287 574.139 20.4899 570.661 18.8261L560 17.5565C562.496 19.1131 564.974 20.7391 567.409 22.4783Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M534.721 3.82619L530.939 2.27835H521.374C522.774 2.83488 524.174 3.33923 525.582 3.82619H534.721Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M366.087 4.97393C366.209 4.20001 366.348 3.4348 366.496 2.67828H356.93C356.704 3.4522 356.487 4.24349 356.287 5.02611L366.087 4.97393Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M346.461 5.16531C346.727 4.35951 347.017 3.55951 347.33 2.76531H337.722C337.33 3.5827 336.956 4.4088 336.591 5.22618L346.461 5.16531Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M356.278 5.08699C356.478 4.30439 356.695 3.51308 356.922 2.73917L347.356 2.78266C347.049 3.57686 346.759 4.37684 346.487 5.18263L356.278 5.08699Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M375.992 4.8696C375.992 4.11308 376.078 3.36524 376.139 2.63481L366.522 2.6783C366.374 3.43483 366.235 4.20003 366.113 4.97395L375.992 4.8696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M385.826 4.7566C385.826 4.02616 385.765 3.29571 385.757 2.58266L376.139 2.63483C376.078 3.36527 376.026 4.11312 375.991 4.86965L385.826 4.7566Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M306.843 5.4522C307.487 4.58264 308.139 3.71308 308.817 2.84351H299.13C298.339 3.71308 297.574 4.58264 296.809 5.4522H306.843Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M316.861 5.39133C317.391 4.52176 317.93 3.65221 318.487 2.84351H308.817C308.139 3.71308 307.487 4.58264 306.843 5.4522L316.861 5.39133Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M326.748 5.33047C327.182 4.4609 327.617 3.6609 328.113 2.82611H318.487C317.93 3.69568 317.391 4.52176 316.861 5.37393L326.748 5.33047Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M336.582 5.25221C336.948 4.43482 337.321 3.60871 337.713 2.79132H328.148C327.678 3.66089 327.217 4.4609 326.782 5.29568L336.582 5.25221Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M554.025 105.217C546.199 105.357 538.373 105.348 530.547 105.217C533.533 108.365 536.507 111.516 539.469 114.67H564.782C561.243 111.496 557.678 108.348 554.112 105.191L554.025 105.217Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M483.008 3.84349C482.426 3.31306 481.852 2.76522 481.269 2.20869H471.704C472.174 2.78261 472.661 3.33913 473.156 3.88696L483.008 3.84349Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M463.356 3.97397C462.948 3.40005 462.548 2.81744 462.148 2.23483H452.582L453.652 3.97397H463.356Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M473.148 3.89572C472.652 3.34789 472.165 2.79137 471.696 2.21746H462.131C462.531 2.79137 462.931 3.37398 463.339 3.95659L473.148 3.89572Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M443.974 4.13053L443.052 2.31314H433.487L434.278 4.1914L443.974 4.13053Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M415.148 4.42613C414.93 3.76525 414.722 3.1044 414.53 2.43483L404.965 2.487C405.113 3.16527 405.278 3.85222 405.443 4.53918L415.148 4.42613Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M395.652 4.64355C395.548 3.9392 395.461 3.23486 395.382 2.5392L385.774 2.58267C385.774 3.29572 385.774 4.02616 385.843 4.7566L395.652 4.64355Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M405.443 4.53916C405.278 3.85221 405.113 3.16527 404.965 2.48701L395.4 2.53918C395.478 3.23483 395.565 3.93916 395.669 4.6435L405.443 4.53916Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M424.756 4.32174L424.034 2.39132L414.53 2.43481C414.721 3.10437 414.93 3.76522 415.147 4.42608L424.756 4.32174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M434.322 4.21741L433.53 2.33915H424.035L424.756 4.26958L434.322 4.21741Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M269.809 2.80872H260.192C258.887 3.67828 257.583 4.54784 256.313 5.49566H266.148C267.348 4.59711 268.568 3.70147 269.809 2.80872Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M210.261 44.3305C212.58 43.4262 214.791 42.4552 216.896 41.4175C212.461 40.5914 207.635 39.6783 203.2 38.8522C207.635 39.6522 212.461 40.5914 216.896 41.4175C219.122 40.3392 221.244 39.2001 223.357 38.0001C219.522 37.3653 214.904 36.5827 211.052 35.9305C214.904 36.5827 219.522 37.3653 223.357 38.0001C225.539 36.7479 227.644 35.4436 229.705 34.0783L218.705 32.4783L229.705 34.0783C231.791 32.6957 233.826 31.2609 235.791 29.7827C232.435 29.4001 228.896 28.9827 225.531 28.5653C228.896 28.9827 232.435 29.4349 235.791 29.7827C237.783 28.3131 239.748 26.8001 241.687 25.261C238.348 25.0001 234.974 24.7218 231.626 24.3914C234.974 24.7131 238.348 24.9914 241.687 25.261C243.531 23.7914 245.357 22.2957 247.183 20.7914C243.878 20.6175 240.571 20.4117 237.261 20.174C233.557 23.0349 229.791 25.8088 225.835 28.3653C215.913 34.7914 205.487 38.1392 194.426 41.774C199.409 42.6957 204.409 43.6696 209.409 44.6436L210.261 44.3305Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M188.461 52.4522C183.4 51.2522 177.626 49.9565 172.574 48.9131C168.574 50.2348 164.583 51.5855 160.6 52.9652C165.669 54 171.487 55.3391 176.574 56.6261C180.539 55.2464 184.501 53.8551 188.461 52.4522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M176.574 56.6261C171.487 55.3392 165.669 54.0174 160.6 52.9653C156.443 54.4203 152.313 55.9305 148.208 57.4957C153.495 58.3653 158.939 59.574 164.252 60.9131L176.574 56.6261Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M199.6 48.4C194.478 47.3044 189.104 46.1739 184.017 45.1826C189.104 46.1739 194.452 47.3044 199.6 48.4ZM199.6 48.4C202.887 47.174 206.165 45.9305 209.443 44.6435C204.443 43.6696 199.443 42.6957 194.46 41.774L193.087 42.2174C186.295 44.4522 179.434 46.6522 172.574 48.9131C177.626 49.9566 183.4 51.2522 188.46 52.4522C192.182 51.1305 195.895 49.7798 199.6 48.4Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M29.513 113.783L54.8869 114.504C63.1216 110.374 71.2782 106.191 79.4173 101.774C72.1999 101.704 61.8521 101.574 54.6695 101.409C36.6173 110.304 18.5738 119.183 0.434692 127.878H27.9912C37.0608 123.339 46.0173 118.948 54.8869 114.487L29.513 113.783Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M119.478 79.5392C116.58 79.4696 113.664 79.4174 110.73 79.3826H109.757C106.783 79.3479 103.797 79.3305 100.8 79.3305C103.803 79.3305 106.788 79.3479 109.757 79.3826C106.426 79.3826 103.078 79.3305 99.9218 79.3305C93.2261 82.5363 86.5421 85.771 79.8696 89.0348C87 89.0348 95.0348 88.9392 102.191 88.8783C107.826 85.6783 113.617 82.5392 119.478 79.5392Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M93.8259 93.661C96.5738 92.061 99.3622 90.4639 102.191 88.8697C95.0346 88.9305 86.9999 89.0262 79.8694 89.0262C71.4636 93.1306 63.0578 97.2523 54.652 101.391C61.8346 101.557 72.1825 101.687 79.3999 101.757C84.2259 99.1479 89.0346 96.4697 93.8259 93.661Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M150.643 65.7392L151.374 65.4784C145.754 64.3686 140.071 63.6077 134.357 63.2001L132 64.2175C127.304 66.3653 122.635 68.5653 117.957 70.7392C124.043 70.7392 130.435 71.0175 136.217 71.5131C141.017 69.4349 145.835 67.4871 150.643 65.7392Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M110.73 79.3827C113.73 79.3827 116.704 79.4696 119.478 79.5392C125.009 76.7218 130.6 74.0261 136.226 71.5479C130.435 71.0522 124.052 70.774 117.965 70.774C111.936 73.5914 105.925 76.4406 99.9304 79.3218C103.087 79.3218 106.435 79.3218 109.765 79.374L110.73 79.3827Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M164.252 60.9132C158.939 59.5741 153.496 58.3827 148.209 57.4958C143.53 59.2871 138.904 61.174 134.357 63.2001C140.071 63.6077 145.754 64.3686 151.374 65.4784C155.658 63.9306 159.951 62.4088 164.252 60.9132Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M252.435 16.4696C249.139 16.3478 245.843 16.2174 242.548 16.0522C240.809 17.4348 239.069 18.8174 237.33 20.1739C240.646 20.4058 243.954 20.6116 247.252 20.7913L252.435 16.4696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M337.713 2.79131C338.061 2.06088 338.417 1.33046 338.783 0.600037H329.391C328.948 1.33916 328.522 2.07828 328.113 2.82609L337.713 2.79131Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M272.982 0.591385C271.904 1.32182 270.852 2.06096 269.808 2.80879H279.652C280.521 2.05227 281.46 1.29573 282.4 0.565299C281.46 1.29573 280.556 2.05227 279.652 2.80879H289.426C290.208 2.05227 291 1.30445 291.808 0.556622C291 1.30445 290.208 2.05227 289.426 2.80879H299.13C299.808 2.05227 300.495 1.30445 301.2 0.556622C300.495 1.30445 299.808 2.05227 299.13 2.80879H308.808C309.391 2.05227 309.982 1.30445 310.6 0.556622C309.982 1.30445 309.391 2.05227 308.808 2.80879H318.478C318.973 2.06096 319.469 1.31312 319.991 0.565299C319.469 1.31312 318.973 2.06096 318.478 2.80879H328.104C328.521 2.06096 328.974 1.32184 329.382 0.582707C307.44 0.582707 285.501 0.582707 263.565 0.582707C262.417 1.29575 261.295 2.02617 260.182 2.774H269.8C270.852 2.06095 271.904 1.32182 272.982 0.591385Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M366.522 2.67838C366.661 1.97112 366.809 1.27545 366.965 0.591385H357.565C357.339 1.29574 357.122 2.0088 356.922 2.72184L366.522 2.67838Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M347.322 2.76531C347.6 2.03487 347.879 1.31313 348.192 0.591385H338.783C338.418 1.32182 338.061 2.05228 337.713 2.78271L347.322 2.76531Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M257.391 12.4088C254.104 12.3392 250.811 12.2581 247.513 12.1653L242.591 16.0523C245.886 16.2175 249.182 16.3479 252.478 16.4697C254.078 15.1131 255.715 13.7595 257.391 12.4088Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M629.009 64.5392C635.229 64.2088 641.452 63.9189 647.678 63.6697C643.4 61.5595 639.203 59.374 635.087 57.1132C629.023 57.2813 622.965 57.4378 616.913 57.5827C620.817 60.0262 624.843 62.3566 629.009 64.5392Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M261.939 8.73049C258.643 8.73049 255.356 8.66092 252.061 8.60874C250.522 9.78265 248.991 10.974 247.469 12.1653C250.768 12.2638 254.061 12.345 257.348 12.4087C258.861 11.174 260.391 9.94788 261.939 8.73049Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M266.148 5.48695H256.313C254.878 6.51304 253.47 7.56522 252.061 8.63479C255.357 8.68697 258.644 8.72176 261.939 8.75654C263.322 7.64349 264.722 6.55652 266.148 5.48695Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M356.922 2.72186C357.122 2.00881 357.339 1.29574 357.565 0.591385H348.174C347.878 1.31314 347.6 2.03489 347.305 2.76533L356.922 2.72186Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M539.487 114.704C543.556 119.052 547.6 123.4 551.556 127.878H579.13C574.443 123.4 569.643 119.026 564.8 114.696L539.487 114.704Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M488.809 114.731C491.365 119.078 493.922 123.487 496.443 127.878H524C520.765 123.467 517.504 119.078 514.217 114.713L488.809 114.731Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M667.617 114.417C674.864 118.893 682.11 123.38 689.357 127.878H716.913C709.104 123.357 701.325 118.815 693.574 114.252L667.617 114.417Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M54.8868 114.487C46.0172 118.948 37.0607 123.339 27.9911 127.878H55.5476C64.3389 123.278 73.4346 119.444 82.4085 115.296L54.8868 114.487Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M641.887 114.539C648.574 118.887 655.217 123.348 661.783 127.878C655.217 123.348 648.574 118.922 641.887 114.539ZM641.887 114.539L616.044 114.617C622.209 118.965 628.304 123.313 634.226 127.87C628.304 123.339 622.209 118.948 616.044 114.617H590.174C595.8 118.965 601.348 123.313 606.696 127.835C601.374 123.304 595.826 118.939 590.174 114.617H564.783C569.626 118.965 574.426 123.313 579.113 127.8H689.339C682.104 123.296 674.858 118.809 667.6 114.339L641.887 114.539Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M743.748 100.809C736.6 100.904 727.991 101.044 720.852 101.165C728.806 105.368 736.765 109.559 744.73 113.739C752.73 113.644 761.635 113.522 769.635 113.4C761.635 113.522 752.73 113.644 744.73 113.739C753.797 118.493 762.89 123.206 772.009 127.878H799.565C780.933 118.904 762.327 109.881 743.748 100.809Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M693.556 114.252C701.313 118.809 709.093 123.351 716.895 127.878H744.452C736.046 123.316 727.672 118.699 719.33 114.026C710.739 114.113 702.148 114.188 693.556 114.252Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M719.33 114.026C727.678 118.664 736.052 123.281 744.452 127.878H772.009C762.89 123.241 753.797 118.528 744.73 113.739C736.261 113.855 727.794 113.951 719.33 114.026Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M514.217 114.713C517.493 119.084 520.754 123.473 524 127.878H551.556C547.6 123.426 543.556 119.052 539.487 114.704L514.217 114.713Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M107.913 116.07C99.6607 120.026 91.1477 123.47 83.1042 127.878H110.661C117.774 123.791 125.322 120.583 132.582 116.835L107.913 116.07Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M234.695 120.339L231.217 120.226L207.052 119.357C202.326 121.95 197.743 124.795 193.322 127.878C197.743 124.795 202.326 121.95 207.052 119.357C198.936 119.061 190.843 118.771 182.774 118.487C176.956 121.33 171.6 123.817 165.765 127.861C171.6 123.817 176.956 121.33 182.774 118.487L154.678 117.539C149.182 120.948 143.687 124.365 138.208 127.861C143.687 124.383 149.182 120.904 154.678 117.539L132.574 116.826C125.313 120.574 117.791 123.783 110.652 127.87H220.869C225.113 123.348 229.626 122.243 234.695 120.339Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M82.4081 115.296C73.4342 119.443 64.3385 123.278 55.5472 127.878H83.1037C91.1472 123.47 99.6255 120.052 107.912 116.07L82.4081 115.296Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M258.322 120.6C250.455 120.658 242.58 120.571 234.696 120.339C229.626 122.244 225.131 123.304 220.896 127.878H248.452C251.339 124.949 254.67 122.492 258.322 120.6V120.6Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M554.113 105.217C557.678 108.374 561.243 111.522 564.782 114.696H590.174C585.826 111.374 581.478 108.078 577.043 104.783C569.403 104.933 561.759 105.078 554.113 105.217Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M310.113 118.765C307.725 121.652 305.535 124.697 303.556 127.878H331.113C332.565 124.165 334.191 120.731 335.782 117.322C327.226 117.8 318.669 118.304 310.113 118.765Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M438.009 114.8C439.157 119.148 440.278 123.496 441.339 127.844C440.278 123.496 439.157 119.148 438.009 114.8ZM438.009 114.8L412.461 114.878C412.957 119.226 413.418 123.574 413.783 127.87C413.418 123.591 412.913 119.252 412.461 114.878H399.913C395.687 114.878 391.464 114.936 387.244 115.052C386.905 119.313 386.539 123.574 386.226 127.826C386.539 123.574 386.905 119.313 387.244 115.052C378.699 115.238 370.157 115.528 361.618 115.922C360.661 119.87 359.644 123.809 358.67 127.818C359.644 123.809 360.661 119.87 361.618 115.922C353 116.313 344.392 116.791 335.783 117.27C334.192 120.678 332.565 124.113 331.113 127.826H468.887C467.102 123.444 465.293 119.07 463.461 114.704L438.009 114.8Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M463.478 114.757C465.304 119.128 467.113 123.501 468.904 127.878H496.461C493.939 123.487 491.382 119.104 488.826 114.73C480.362 114.73 471.913 114.739 463.478 114.757Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M283.939 119.991C280.992 122.299 278.327 124.947 276 127.878H303.556C305.535 124.697 307.725 121.652 310.113 118.765C301.391 119.235 292.669 119.661 283.939 119.991Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M283.939 119.991C275.409 120.304 266.87 120.531 258.322 120.6C254.67 122.492 251.339 124.949 248.452 127.878H276C278.327 124.947 280.992 122.299 283.939 119.991V119.991Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M579.904 58.4522L598.443 58.0435C594.739 55.3479 591.165 52.5305 587.661 49.6348L569.913 49.1913C573.148 52.3826 576.443 55.5218 579.904 58.4522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M581.095 24.2175C586.121 24.8696 591.113 25.487 596.078 26.0349C592.455 24.0928 588.829 22.2754 585.2 20.5827C580.365 20.0262 575.504 19.4262 570.643 18.8436C574.139 20.5189 577.623 22.3102 581.095 24.2175Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M590.243 29.7044L590.591 29.9305C595.721 30.5392 600.817 31.1044 605.878 31.5914C605.008 31.0609 604.061 30.5479 603.148 30.0088C600.791 28.6262 598.434 27.3218 596.078 26.061C591.113 25.5131 586.121 24.8957 581.095 24.2436C584.139 25.9566 587.191 27.7392 590.243 29.7044Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M649.383 64.5219C653.8 66.661 658.206 68.8291 662.6 71.0262C668.887 70.8696 675.374 70.774 681.67 70.774L667.991 64.2175L665.8 63.1653C659.759 63.2639 653.719 63.4291 647.678 63.661L649.383 64.5219Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M562.07 14.9566C566.67 15.4261 571.27 15.9305 575.887 16.4174C573.452 15.374 571.012 14.3508 568.565 13.3479C564.218 12.9653 559.957 12.5913 555.679 12.2522C557.818 13.1334 559.948 14.0348 562.07 14.9566Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M555.652 12.2523C559.93 12.5914 564.226 12.9653 568.539 13.3479C566.684 12.5885 564.826 11.8407 562.965 11.1045C558.936 10.8146 554.922 10.5538 550.922 10.3219C552.53 10.9566 554.113 11.6001 555.652 12.2523Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M550.948 10.3044C554.948 10.5421 558.962 10.803 562.991 11.087L558.644 9.34792C554.829 9.13922 551.029 8.95661 547.244 8.80009C548.461 9.32183 549.713 9.81749 550.948 10.3044Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M679.444 79.5479C685.974 79.4088 692.913 79.3218 699.443 79.3218C693.519 76.4638 687.591 73.6146 681.661 70.774C675.365 70.774 668.878 70.8696 662.591 71.0261C668.226 73.8319 673.844 76.6725 679.444 79.5479Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M570.643 18.8522C575.504 19.4522 580.365 20.0522 585.199 20.5913C582.104 19.1305 579 17.7334 575.886 16.4001C571.269 15.9131 566.669 15.4088 562.069 14.9392C564.939 16.2001 567.826 17.487 570.643 18.8522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M624.982 103.53C630.626 107.191 636.287 110.843 641.887 114.539L667.617 114.417L648.93 102.878L624.982 103.53Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M547.217 8.82616C551.003 8.98268 554.803 9.1653 558.617 9.37399L554.956 7.9479C551.426 7.81456 547.907 7.7334 544.4 7.70442L547.217 8.82616Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M600.965 104.217C606.009 107.696 611.052 111.174 616.044 114.652L641.887 114.574C636.27 110.878 630.626 107.226 624.983 103.565L600.965 104.217Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M648.931 102.878L667.618 114.417L693.557 114.252L673.122 102.235C665.064 102.438 657 102.652 648.931 102.878Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M577.043 104.748C581.443 108.044 585.835 111.339 590.174 114.661H616.044C611.052 111.183 606.009 107.704 600.965 104.226L577.043 104.748Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M698.096 89.2087C705.096 89.0957 712.487 89.0262 719.496 89.0262C712.817 85.7798 706.151 82.545 699.496 79.3218C692.957 79.3218 686.017 79.4087 679.496 79.5479C685.699 82.7421 691.899 85.9624 698.096 89.2087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M673.122 102.235L693.557 114.252C702.148 114.183 710.739 114.107 719.331 114.026C711.962 109.91 704.603 105.783 697.252 101.644C689.229 101.829 681.186 102.026 673.122 102.235Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M675.939 89.7305C683.058 93.6783 690.162 97.6493 697.252 101.644C705.119 101.47 712.985 101.31 720.852 101.165C713.27 97.1769 705.684 93.1914 698.096 89.2087C690.704 89.3595 683.319 89.5334 675.939 89.7305Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M720.87 101.165C728.009 101.044 736.617 100.904 743.765 100.809L719.513 89.0261C712.504 89.0261 705.113 89.0957 698.113 89.2087C705.701 93.174 713.287 97.1595 720.87 101.165Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M719.33 114.026C727.8 113.939 736.267 113.844 744.73 113.739C736.765 109.565 728.806 105.374 720.852 101.165C712.98 101.31 705.113 101.47 697.252 101.644C704.609 105.783 711.968 109.91 719.33 114.026Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M521.339 2.2349C520.035 1.72185 518.73 1.17402 517.452 0.574013H508.026C508.956 1.18271 509.896 1.72184 510.852 2.22619L521.339 2.2349Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M433.53 2.34795L432.774 0.608784H423.383C423.591 1.20879 423.817 1.82619 424.035 2.4262L433.53 2.34795Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M443.052 2.31315L442.183 0.574013H432.774L433.53 2.31315H443.052Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M424.035 2.39139C423.817 1.7914 423.591 1.17401 423.383 0.574013H413.974C414.148 1.1914 414.339 1.82619 414.53 2.43489L424.035 2.39139Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M452.6 2.26962L451.574 0.530518H442.174L443.043 2.26962H452.6Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M395.365 2.53918C395.287 1.87831 395.217 1.22614 395.165 0.582657H385.765C385.765 1.23483 385.765 1.90439 385.765 2.58265L395.365 2.53918Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M385.756 2.58263C385.756 1.90437 385.756 1.23482 385.756 0.582657H376.356C376.269 1.25222 376.2 1.93915 376.139 2.6348L385.756 2.58263Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M414.53 2.47829C414.339 1.86959 414.148 1.2348 413.974 0.617405H404.574C404.696 1.25219 404.826 1.89568 404.965 2.53046L414.53 2.47829Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M462.148 2.24355C461.756 1.68702 461.356 1.13048 460.974 0.565258H451.574L452.6 2.30439L462.148 2.24355Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M404.965 2.4783C404.826 1.84352 404.696 1.20004 404.574 0.565258H395.165C395.217 1.20873 395.287 1.8609 395.365 2.52177L404.965 2.4783Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M541.269 6.46094C544.695 6.46094 548.124 6.51314 551.556 6.61749L548.139 5.28705L538.017 5.15659L541.269 6.46094Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M376.139 2.63481C376.2 1.93916 376.269 1.25222 376.356 0.582657H366.956C366.8 1.27251 366.652 1.96815 366.513 2.6696L376.139 2.63481Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M530.939 2.27833C529.588 1.69862 528.235 1.11891 526.878 0.539196H517.452C518.739 1.1392 520.061 1.68703 521.339 2.20008L530.939 2.27833Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M540.626 2.32178L530.939 2.27828L534.722 3.8261C537.895 3.8261 541.365 3.82609 544.548 3.87827C541.365 3.87827 537.895 3.87827 534.722 3.8261L538.017 5.15652L548.139 5.28698C544.235 3.75655 540.313 2.20004 536.295 0.582657H526.878C528.235 1.16236 529.588 1.74207 530.939 2.32178H540.626Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M544.4 7.6957C547.913 7.73048 551.432 7.81164 554.956 7.93918L551.556 6.60876C548.125 6.5218 544.696 6.46963 541.27 6.45224L544.4 7.6957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M481.304 2.20875C480.783 1.66962 480.269 1.13048 479.774 0.565258H470.374C470.8 1.12178 471.243 1.67832 471.696 2.21745L481.304 2.20875Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M471.696 2.21744C471.244 1.67831 470.826 1.12178 470.374 0.565258H460.974C461.357 1.13047 461.757 1.68701 462.148 2.24353L471.696 2.21744Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M491.07 2.20009C490.426 1.67835 489.791 1.13054 489.183 0.556622H479.774C480.27 1.12184 480.783 1.66096 481.304 2.20009H491.07Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M510.852 2.21745C509.895 1.71311 508.956 1.17395 508.026 0.565258H498.6C499.349 1.14891 500.127 1.69453 500.93 2.20005L510.852 2.21745Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M500.93 2.20002C500.126 1.69451 499.349 1.1489 498.6 0.565258H489.182C489.791 1.13916 490.426 1.687 491.069 2.20873L500.93 2.20002Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M600.374 36.2087C605.643 36.7044 611.104 37.1913 616.33 37.5566C611.113 37.1913 605.643 36.687 600.374 36.2087ZM600.374 36.2087C604.061 38.574 607.75 40.8928 611.443 43.1653C616.661 43.4522 622.747 43.7392 627.965 43.9218C622.747 43.7392 616.661 43.4522 611.443 43.1653C615.316 45.6058 619.211 47.9855 623.13 50.3044C628.347 50.3566 634.861 50.3913 640.087 50.3739C634.869 50.3739 628.365 50.3739 623.13 50.3044C627.066 52.6232 631.052 54.8957 635.087 57.1218L652.426 56.6522L635.087 57.1218C639.208 59.3826 643.405 61.5681 647.678 63.6783C653.713 63.4464 659.753 63.2812 665.8 63.1826C645.304 53.3652 625.53 43.0087 605.878 31.5914C600.817 31.1044 595.721 30.5392 590.591 29.9305C593.872 32.0348 597.133 34.1276 600.374 36.2087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M266.722 104.374C263.67 105.322 260.548 106.183 257.452 107.322C265.099 107.583 272.754 107.368 280.374 106.678C282.348 105.443 284.322 104.261 286.252 103.043C279.78 103.932 273.255 104.377 266.722 104.374Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M225.304 98.5565C220.936 98.9471 216.617 99.7655 212.409 101C220.104 102.591 227.817 104.061 235.583 105.2C239.331 104.174 243.2 103.565 247.009 102.852C239.722 101.809 232.487 100.304 225.304 98.5565Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M262.896 104.304C257.578 104.104 252.275 103.619 247.009 102.852C243.2 103.565 239.33 104.174 235.583 105.2C240.974 105.991 246.391 106.626 251.843 107.026C253.713 107.165 255.591 107.252 257.452 107.322C260.548 106.183 263.67 105.322 266.722 104.374C265.443 104.365 264.174 104.348 262.896 104.304Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M205.105 93.1913C200.084 93.7157 195.131 94.753 190.322 96.287C197.696 97.8435 205.061 99.4696 212.444 101C216.652 99.7655 220.971 98.9471 225.34 98.5565C218.54 96.9217 211.809 95.0696 205.105 93.1913Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M162.174 83.0435C156.512 84.7021 151.027 86.9157 145.8 89.6522C153.409 90.1653 161 90.9566 168.556 92.1566C173.811 90.0948 179.271 88.5993 184.843 87.6957C177.226 85.7044 169.687 84.2175 162.174 83.0435Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M365.443 84.2175C365.443 86.8262 365.496 89.4349 365.443 92.0436C373.017 90.9132 380.617 90.061 388.278 89.6349C388.156 86.8175 387.896 83.9479 387.687 81.0784C380.214 81.6594 372.785 82.7078 365.443 84.2175V84.2175Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M365.443 84.2174C358.269 85.6609 351.156 87.4261 344.069 89.3131C343.791 91.5826 343.522 93.8261 343.2 95.9305C350.591 94.4783 357.982 93.1305 365.435 92.0261C365.496 89.4783 365.452 86.8261 365.443 84.2174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M269.565 38.8348C265.365 38.487 261.165 38.0696 256.965 37.6087C255.226 39.2435 253.382 40.8261 251.487 42.3478C255.835 42.9391 260.226 43.4783 264.609 43.9391C266.313 42.2754 267.965 40.5739 269.565 38.8348Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M324.435 94.7131C323.565 96.5566 322.696 98.4 321.826 100.235C328.939 98.8174 336.035 97.3479 343.148 95.9566C343.487 93.8522 343.757 91.6087 344.017 89.3392C337.513 91.087 330.983 92.9131 324.435 94.7131Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M304.609 99.7131C303.348 101.157 302.07 102.609 300.757 104.061C307.809 102.931 314.844 101.6 321.861 100.2C322.731 98.3653 323.6 96.5218 324.47 94.6783C317.844 96.5044 311.244 98.2261 304.609 99.7131Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M185.843 87.9044L184.843 87.6523C179.269 88.5699 173.81 90.0799 168.556 92.1566C169.33 92.2783 170.113 92.3914 170.887 92.5218C177.374 93.6262 183.835 94.9305 190.287 96.287C195.096 94.7531 200.049 93.7158 205.069 93.1914C198.669 91.4001 192.252 89.574 185.843 87.9044Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M251.304 54.8957C252.339 54.1827 253.339 53.4609 254.322 52.7218C249.409 52.0174 244.496 51.2174 239.583 50.3653C236.835 51.9305 234.104 53.5305 231.4 55.1305C236.617 56.1566 241.774 57.1218 246.983 57.974C248.4 56.9305 249.835 55.8957 251.304 54.8957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M246.956 57.974C241.739 57.1044 236.521 56.1566 231.373 55.1305C228.295 56.9566 225.234 58.774 222.165 60.5653C227.687 61.8435 233.226 63.0522 238.782 64.1392C241.513 62.0609 244.226 59.9827 246.956 57.974Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M630.521 65.3392C634.643 67.4552 638.745 69.5914 642.826 71.7479C649.417 71.4407 656.008 71.2001 662.6 71.0262C658.2 68.8349 653.794 66.6668 649.382 64.5219L647.643 63.6523C641.417 63.8842 635.194 64.174 628.974 64.5219L630.521 65.3392Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M198.33 85.1826C191.304 82.8869 184.347 80.8 177.391 79C172.26 80.2869 167.165 81.5478 162.156 83.0783C169.669 84.2348 177.208 85.7391 184.826 87.687C189.313 86.8261 193.826 86.1217 198.33 85.1826Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M264.591 43.9392C260.243 43.4784 255.843 42.9392 251.469 42.3479C249.626 43.8175 247.722 45.2349 245.722 46.5827C250.296 47.3218 254.869 48.0175 259.452 48.6262C261.235 47.1045 262.939 45.5392 264.591 43.9392Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M207.826 82.6783C211.741 81.3474 215.525 79.6591 219.13 77.6348C212.878 75.7827 206.635 73.7914 200.409 71.7827C196.591 73.4183 192.68 74.8266 188.696 76.0001H188.591C195.009 78.2001 201.417 80.4783 207.835 82.6696L207.826 82.6783Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M238.8 64.1392C233.244 63.0522 227.704 61.8435 222.183 60.5652C218.809 62.5305 215.417 64.4522 211.974 66.2696C217.896 67.887 223.817 69.4435 229.765 70.8522C232.817 68.687 235.817 66.4174 238.8 64.1392Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M207.826 82.6957C201.408 80.5044 195 78.2262 188.582 76.0262C184.869 77.0958 181.147 78.0436 177.434 78.9653C184.339 80.8001 191.287 82.887 198.356 85.1827C201.558 84.5365 204.72 83.7062 207.826 82.6957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M229.765 70.8522C223.817 69.4435 217.896 67.887 211.974 66.2696C208.209 68.2609 204.365 70.1131 200.417 71.7826C206.643 73.7913 212.887 75.7826 219.139 77.6348C222.804 75.5721 226.351 73.3078 229.765 70.8522Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M286.252 103.044C284.322 104.261 282.348 105.444 280.374 106.678C287.2 106.087 293.991 105.183 300.756 104.07C302.069 102.583 303.365 101.131 304.608 99.7218C298.522 101.07 292.408 102.218 286.252 103.044Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M594.104 83.287C598.672 86.3595 603.261 89.4 607.869 92.4087L630.478 91.4435L615.556 82.1913C608.391 82.5681 601.24 82.9334 594.104 83.287Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M611.513 66.4958C615.217 68.5827 618.91 70.6813 622.591 72.7914C629.339 72.4088 636.084 72.061 642.826 71.7479C638.739 69.5914 634.638 67.4552 630.522 65.3393L629.009 64.5392C622.69 64.8755 616.374 65.2436 610.061 65.6436L611.513 66.4958Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M602.348 73.9826C606.736 76.7304 611.125 79.4666 615.513 82.1913C622.69 81.8203 629.867 81.4579 637.044 81.1043C632.238 78.3217 627.42 75.5507 622.591 72.7913C615.844 73.1739 609.096 73.571 602.348 73.9826Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M630.435 91.4435C638.012 91.1247 645.591 90.8145 653.174 90.5131C647.812 87.3652 642.435 84.229 637.043 81.1043C629.872 81.4522 622.696 81.8145 615.513 82.1913L630.435 91.4435Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M637.043 81.1044C644.22 80.7566 651.397 80.4464 658.574 80.174C653.356 77.3334 648.107 74.5247 642.826 71.7479C636.078 72.0494 629.333 72.3972 622.591 72.7914C627.42 75.5508 632.237 78.3218 637.043 81.1044Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M587.661 49.6349C593.609 49.7798 599.556 49.9131 605.504 50.0349C601.809 47.4696 598.165 44.8175 594.513 42.1566C588.861 41.7827 583.209 41.3827 577.522 40.9914C580.87 43.9044 584.243 46.8262 587.661 49.6349Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M577.522 40.9914C583.191 41.3828 588.826 41.7827 594.513 42.1567C591.104 39.661 587.696 37.1306 584.243 34.6001C578.904 34.0523 573.548 33.4871 568.183 32.948C571.304 35.6175 574.435 38.3132 577.522 40.9914Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M610.061 65.6435C616.374 65.2551 622.69 64.887 629.009 64.5392C624.843 62.3566 620.817 60.0261 616.913 57.5827L598.443 58.0349C602.156 60.7392 606.008 63.2783 610.061 65.6435Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M598.444 58.0435L616.913 57.5913C613.026 55.1652 609.244 52.6348 605.504 50.0348C599.557 49.9246 593.609 49.7913 587.661 49.6348C591.165 52.5304 594.783 55.3478 598.444 58.0435Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M470.635 83.4697C472.374 86.5131 474.113 89.5479 475.852 92.574C483.374 92.9914 490.904 93.3827 498.461 93.7044C496.148 90.6001 493.826 87.4957 491.504 84.374C484.554 84.1073 477.597 83.8059 470.635 83.4697Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M450.156 82.4783C451.316 85.4348 452.501 88.3855 453.713 91.3304C461.096 91.7391 468.496 92.2 475.878 92.5652C474.139 89.5391 472.4 86.5044 470.661 83.4609L450.156 82.4783Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M399.722 80.7391C395.67 80.687 391.652 80.8348 387.661 81.1305C387.87 84 388.131 86.8696 388.252 89.687C392.148 89.4696 396.078 89.3566 399.991 89.3913C403.235 89.3913 406.47 89.4696 409.713 89.5392C409.461 86.6609 409.244 83.7913 409.078 80.9304C405.965 80.8029 402.847 80.7391 399.722 80.7391Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M429.608 81.6087C430.263 84.5072 430.945 87.4058 431.652 90.3043C439.008 90.6232 446.362 90.9884 453.713 91.4C452.501 88.4493 451.316 85.4986 450.156 82.5478C443.304 82.1768 436.455 81.8638 429.608 81.6087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M409.078 80.8869C409.244 83.7478 409.461 86.6174 409.713 89.4956C417.026 89.6522 424.339 89.9217 431.652 90.2434C430.939 87.3449 430.258 84.4463 429.609 81.5478C422.774 81.2869 415.931 81.0666 409.078 80.8869Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M532.565 85.1914H532.017C535.33 88.3305 538.695 91.4262 542.078 94.4871H542.948C549.626 94.3914 556.304 94.2349 562.947 94.0523C559.104 91.061 555.336 88.0175 551.643 84.9218C545.287 85.087 538.93 85.1653 532.565 85.1914Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M572.73 84.2174C576.869 87.2957 581.06 90.3218 585.304 93.2957C592.84 93.0175 600.362 92.7276 607.869 92.4262C603.26 89.4117 598.672 86.3711 594.104 83.3044C586.985 83.6406 579.86 83.945 572.73 84.2174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M551.643 84.9132C555.353 88.0204 559.121 91.0639 562.948 94.0436C570.391 93.8349 577.832 93.5914 585.269 93.3132C581.037 90.3277 576.846 87.3016 572.695 84.2349C565.692 84.5132 558.675 84.7393 551.643 84.9132Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M491.521 84.374C493.817 87.4957 496.139 90.6 498.478 93.7044C505.765 94.0087 513.06 94.2522 520.356 94.3913C517.458 91.2783 514.559 88.1421 511.66 84.9827C504.953 84.8378 498.24 84.6349 491.521 84.374Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M511.678 84.9826C514.53 88.1479 517.429 91.2841 520.374 94.3914C527.617 94.5392 534.87 94.5914 542.113 94.487C538.73 91.4261 535.365 88.3305 532.052 85.1913C525.246 85.2087 518.455 85.1392 511.678 84.9826Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M290.844 12.6523C287.244 12.6523 283.641 12.6523 280.035 12.6523C278.736 14.0957 277.455 15.545 276.191 17.0001C279.925 17.0349 283.649 17.0523 287.365 17.0523C288.513 15.5798 289.673 14.1131 290.844 12.6523Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M523.087 6.55652C521.348 6.09565 519.548 5.60868 517.8 5.05215C514.078 5.05215 510.354 5.05215 506.626 5.05215C508.365 5.73042 510.104 6.29564 511.913 6.79129C515.635 6.71013 519.359 6.63188 523.087 6.55652Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M499.131 7.28695C497.8 6.61739 496.522 5.90435 495.235 5.13045C491.687 5.13045 488.139 5.16526 484.592 5.23482C485.652 6.11017 486.751 6.95942 487.887 7.7826C491.652 7.59999 495.4 7.44347 499.131 7.28695Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M511.913 6.82607C510.122 6.30433 508.366 5.73912 506.626 5.08695C502.835 5.08695 499.044 5.10724 495.253 5.14782C496.513 5.92173 497.861 6.63475 499.148 7.30432C503.403 7.11881 507.658 6.9594 511.913 6.82607Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M516.574 8.14782C520.33 8.00289 524.098 7.88405 527.878 7.7913L523.113 6.55653C519.385 6.6261 515.66 6.71886 511.939 6.8348C513.443 7.27827 515 7.69565 516.574 8.14782Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M466.956 8.82616C466.087 7.7392 465.278 6.62615 464.461 5.5131L454.626 5.68703C455.362 6.91602 456.104 8.1363 456.852 9.3479L466.956 8.82616Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M516.87 8.22607C521.139 8.22607 525.4 8.22609 529.678 8.29565L527.852 7.79131C524.09 7.87826 520.322 7.9971 516.548 8.14782L516.87 8.22607Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M446.852 9.83485C446.182 8.5218 445.522 7.22616 444.861 5.88702L435.104 6.09571C435.678 7.48702 436.261 8.87833 436.843 10.2783C440.182 10.1392 443.519 9.99137 446.852 9.83485Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M477.452 8.29576C476.444 7.33924 475.452 6.36533 474.496 5.35663L464.496 5.51315C465.313 6.62619 466.148 7.73923 466.991 8.82618L477.452 8.29576Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M487.905 7.78263C486.745 6.96524 485.647 6.11597 484.609 5.23482L474.496 5.35654C475.452 6.36523 476.444 7.33915 477.452 8.29566C480.942 8.11596 484.426 7.94494 487.905 7.78263Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M456.887 9.34779C456.139 8.1304 455.397 6.91012 454.661 5.68694L444.861 5.88695C445.522 7.19999 446.183 8.49562 446.852 9.83474L456.887 9.34779Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M545.722 25.2348C548 27.2609 550.209 29.3566 552.383 31.4783C557.652 31.9189 562.919 32.4087 568.183 32.9479C565.643 30.8 563.07 28.6609 560.426 26.5739L559.904 26.1479C554.922 25.5652 549.93 25.0174 544.939 24.5479L545.722 25.2348Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M560.739 39.8696C566.328 40.2116 571.922 40.5797 577.522 40.9739C574.435 38.2957 571.33 35.6 568.183 32.9305C562.925 32.3972 557.658 31.9073 552.383 31.4609C555.217 34.2348 557.983 37.0609 560.739 39.8696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M569.913 49.1914L587.661 49.6349C584.243 46.8088 580.887 43.9045 577.522 40.9914C571.933 40.5972 566.339 40.2291 560.739 39.887C563.782 43.0088 566.817 46.1305 569.913 49.1914Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M544.939 24.5479C549.93 25.0174 554.921 25.5652 559.904 26.1479C557.6 24.3305 555.252 22.4609 552.843 20.6348C548.061 20.0696 543.278 19.5565 538.487 19.1304C540.643 20.8174 542.774 22.6348 544.939 24.5479Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M532.513 14.9131C537.147 15.2174 541.791 15.6174 546.426 16.0783C544.8 15.0087 543.147 14 541.46 13.0696C536.904 12.7217 532.35 12.4551 527.799 12.2696C529.434 13.0783 530.999 13.9652 532.513 14.9131Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M522.07 9.84349C522.722 10.0696 523.365 10.313 523.991 10.5652C528.47 10.6435 532.948 10.8087 537.426 11.0348C536.191 10.487 534.948 10 533.704 9.56522C529.31 9.43769 524.928 9.36522 520.557 9.34783C521.035 9.48696 521.557 9.66088 522.07 9.84349Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M527.826 12.2696C532.377 12.4551 536.931 12.7218 541.487 13.0696C540.157 12.3305 538.818 11.6522 537.444 11.0348C532.965 10.8087 528.487 10.6435 524.009 10.5652C525.313 11.087 526.583 11.6609 527.826 12.2696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M538.487 19.1305C543.27 19.5566 548.052 20.0696 552.844 20.6348C550.748 19.0522 548.617 17.5131 546.435 16.0783C541.791 15.6174 537.148 15.2087 532.522 14.9131C534.579 16.2182 536.57 17.6258 538.487 19.1305V19.1305Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M516.869 8.22619C518.078 8.57401 519.295 8.92185 520.521 9.32185C524.892 9.32185 529.275 9.39431 533.669 9.53924C532.348 9.07837 531.008 8.66968 529.678 8.29577C525.4 8.2262 521.139 8.2001 516.869 8.22619Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M401.209 11.1739C403.217 11.1739 405.235 11.1304 407.243 11.1044C406.817 9.6435 406.374 8.20003 406.017 6.75656L396.009 7.00003C396.235 8.38263 396.496 9.7826 396.765 11.1913C398.261 11.1739 399.722 11.1739 401.209 11.1739Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M301.782 12.5913C302.652 11.3044 303.542 10.0174 304.452 8.73045C300.974 8.76523 297.496 8.78841 294.017 8.8C292.948 10.087 291.896 11.4087 290.861 12.6522C294.496 12.6464 298.136 12.6261 301.782 12.5913Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M312.8 12.4696C313.484 11.1827 314.194 9.90441 314.93 8.63484L304.452 8.73051C303.536 10.0175 302.646 11.3044 301.782 12.5914C305.452 12.5566 309.124 12.516 312.8 12.4696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M333.765 12.1479C334.241 10.8783 334.739 9.60876 335.261 8.33918L325.13 8.5044C324.504 9.77398 323.901 11.0465 323.322 12.3218L333.765 12.1479Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M323.321 12.3218C323.901 11.0464 324.504 9.77395 325.13 8.50438L314.93 8.63481C314.2 9.90438 313.489 11.1826 312.8 12.4696C316.307 12.429 319.814 12.3798 323.321 12.3218Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M294 8.80009C290.522 8.80009 287.043 8.80009 283.565 8.80009C282.377 10.0697 281.217 11.345 280.087 12.6262C283.693 12.6262 287.296 12.6262 290.896 12.6262C291.878 11.3653 292.93 10.087 294 8.80009Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M279.678 26.826C275.69 26.7739 271.696 26.6898 267.696 26.5739C265.904 28.5478 264.096 30.5043 262.235 32.4087C266.293 32.7391 270.351 33.029 274.409 33.2782C276.209 31.1739 277.948 29.0347 279.678 26.826Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M283.661 21.7827C279.806 21.7827 275.948 21.7421 272.087 21.661C270.626 23.3248 269.177 24.974 267.739 26.6088C271.728 26.719 275.722 26.803 279.722 26.861C281.009 25.1741 282.331 23.4871 283.661 21.7827Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M560.426 26.574C563.034 28.6609 565.643 30.8001 568.182 32.9479C573.547 33.487 578.904 34.0522 584.243 34.6C582.6 33.4 580.947 32.1914 579.278 30.9914L575.191 28.0262C570.113 27.3914 565.008 26.7479 559.904 26.1479L560.426 26.574Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M287.365 17.0349C283.643 17.0349 279.918 17.0175 276.191 16.9827C274.817 18.5392 273.452 20.1044 272.087 21.661C275.947 21.7189 279.805 21.7595 283.66 21.7827C284.887 20.2088 286.087 18.6175 287.365 17.0349Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M365.217 11.5914C365.356 10.2958 365.504 8.98271 365.678 7.73054L355.548 7.95661C355.261 9.21748 355 10.4871 354.748 11.774C358.249 11.7045 361.739 11.6436 365.217 11.5914Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M274.417 33.3044C270.36 33.0609 266.302 32.7711 262.244 32.4348C260.504 34.174 258.742 35.8957 256.957 37.6C261.157 38.0609 265.357 38.4696 269.557 38.8261C271.217 37.0261 272.835 35.174 274.417 33.3044Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M396.766 11.1739C396.496 9.76525 396.235 8.36524 396.009 6.98264L385.974 7.22611C386.07 8.54785 386.192 9.89569 386.322 11.2609C389.8 11.2493 393.281 11.2203 396.766 11.1739Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M417.391 10.9305C416.87 9.46089 416.357 8.00004 415.87 6.53917L406.052 6.77395C406.435 8.20872 406.852 9.6522 407.278 11.1218C410.629 11.0812 414 11.0174 417.391 10.9305Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M427.217 10.6436C426.638 9.20008 426.058 7.7508 425.478 6.29572L415.8 6.52179C416.287 7.98267 416.8 9.44356 417.322 10.9131C420.632 10.8494 423.93 10.7595 427.217 10.6436Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M386.321 11.2956C386.191 9.93043 386.069 8.5826 385.974 7.26086L375.887 7.50434C375.887 8.79129 375.887 10.113 375.887 11.4261C379.353 11.3739 382.832 11.3304 386.321 11.2956Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M436.826 10.3043C436.261 8.90435 435.678 7.51304 435.087 6.12173L425.522 6.33912C426.101 7.78261 426.681 9.23188 427.261 10.687C430.443 10.5536 433.632 10.4261 436.826 10.3043Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M344.408 11.9566C344.756 10.687 345.113 9.41743 345.495 8.15656C342.087 8.21743 338.678 8.28699 335.261 8.33916C334.739 9.60873 334.24 10.8783 333.765 12.1479L344.408 11.9566Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M354.782 11.7739C355.034 10.487 355.295 9.21738 355.582 7.95651L345.504 8.15652C345.121 9.41739 344.765 10.6869 344.417 11.9565L354.782 11.7739Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M375.852 11.4261C375.852 10.0957 375.852 8.81738 375.852 7.50434L365.67 7.73043C365.496 9.00869 365.348 10.2957 365.209 11.5913L375.852 11.4261Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M259.452 48.6262C254.87 48.0175 250.296 47.3218 245.722 46.5827C243.983 47.7653 242.174 48.8957 240.287 49.9566L239.574 50.3653C244.487 51.2349 249.4 52.0175 254.313 52.7218C256.104 51.3914 257.809 50.0262 259.452 48.6262Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M168.556 92.1566C161 90.9566 153.409 90.1653 145.8 89.6522C143.51 90.8755 141.297 92.2375 139.174 93.7305C135.482 96.4388 131.952 99.3597 128.6 102.478C136.235 102.73 143.852 103.087 151.443 103.626C154.646 100.688 158.08 98.0125 161.713 95.6261C163.898 94.2889 166.186 93.1287 168.556 92.1566V92.1566Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M321.861 100.235C314.844 101.635 307.809 102.965 300.757 104.096C299.513 105.504 298.252 106.948 296.948 108.443C295.265 110.38 293.433 112.183 291.47 113.835C299.687 113.017 307.887 111.965 316.087 110.835C317.106 109.326 318.043 107.765 318.896 106.157C319.931 104.217 320.913 102.235 321.861 100.235Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M190.287 96.287C183.835 94.9305 177.374 93.6261 170.887 92.5218C170.113 92.3913 169.33 92.2783 168.556 92.1566C166.186 93.1315 163.898 94.2946 161.713 95.6348C158.08 98.0212 154.646 100.697 151.443 103.635C153.243 103.765 155.043 103.896 156.835 104.044C162.409 104.496 167.956 105.104 173.504 105.783C176.513 103.878 179.513 101.965 182.504 100.009C184.935 98.4525 187.549 97.2025 190.287 96.287V96.287Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M145.8 89.6523C138.844 89.1914 131.835 88.9566 124.835 88.8523C123.279 89.7219 121.722 90.661 120.174 91.6088C114.957 94.8088 110.139 98.461 105.252 102.044C113.079 102.148 120.826 102.296 128.6 102.557C131.951 99.4261 135.482 96.4936 139.174 93.774C141.297 92.2661 143.509 90.8896 145.8 89.6523V89.6523Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M257.452 107.322C255.591 107.252 253.713 107.165 251.844 107.026C246.391 106.626 240.974 105.991 235.583 105.2C232.751 105.929 230.018 106.998 227.443 108.383C224.957 109.757 222.4 111.113 219.809 112.487C226.765 113.417 233.722 114.226 240.765 114.826C241.896 114.922 243.026 114.991 244.148 115.07C246.502 113.18 248.99 111.462 251.591 109.93C253.48 108.921 255.438 108.049 257.452 107.322V107.322Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M280.374 106.678C272.754 107.368 265.099 107.583 257.452 107.322C255.439 108.05 253.48 108.921 251.591 109.931C248.99 111.462 246.502 113.18 244.148 115.07C251.974 115.6 259.8 115.696 267.626 115.461C269.491 114.39 271.276 113.184 272.965 111.852C275.333 109.986 277.806 108.258 280.374 106.678V106.678Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M235.583 105.2C227.817 104.061 220.104 102.591 212.409 101C211.001 101.444 209.626 101.987 208.296 102.626C204.648 104.526 201.184 106.758 197.948 109.296C205.226 110.4 212.513 111.504 219.809 112.487C222.417 111.113 224.956 109.757 227.443 108.383C230.018 106.998 232.751 105.929 235.583 105.2Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M296.948 108.478C298.252 106.948 299.513 105.504 300.756 104.131C293.991 105.217 287.2 106.122 280.374 106.739C277.803 108.319 275.326 110.046 272.956 111.913C271.267 113.244 269.483 114.451 267.617 115.522C275.6 115.287 283.539 114.722 291.469 113.93C293.435 112.259 295.267 110.436 296.948 108.478Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M256.948 37.6087C253.029 37.1739 249.101 36.7044 245.165 36.2C243.063 37.7414 240.88 39.1695 238.626 40.4783C242.916 41.1392 247.197 41.7623 251.469 42.3478C253.365 40.8261 255.182 39.2435 256.948 37.6087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M212.409 101C205.026 99.4696 197.626 97.8435 190.287 96.287C187.548 97.2162 184.933 98.4809 182.505 100.052C179.513 102.009 176.513 103.922 173.505 105.826C181.67 106.852 189.809 108.07 197.948 109.304C201.184 106.767 204.648 104.534 208.296 102.635C209.626 101.993 211.001 101.446 212.409 101V101Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M239.574 50.3652C234.56 49.4899 229.542 48.5652 224.522 47.5913L221.418 49.0435C219.423 49.9826 217.441 50.9101 215.47 51.8261C220.765 52.9652 226.07 54.0783 231.391 55.1304C234.096 53.5304 236.826 51.9304 239.574 50.3652Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M231.391 55.1304C226.07 54.0782 220.765 52.9652 215.47 51.8261C211.991 53.4174 208.626 54.9826 205.209 56.4956C210.852 57.8782 216.513 59.2521 222.183 60.5652C225.217 58.7739 228.313 56.9565 231.391 55.1304Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M240.287 49.9565C242.174 48.8957 243.983 47.7652 245.722 46.5826C241.052 45.8261 236.383 45.0087 231.722 44.1653C229.391 45.3131 226.991 46.4348 224.522 47.5913C229.542 48.5594 234.559 49.4841 239.574 50.3652L240.287 49.9565Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M251.469 42.3479C247.191 41.7682 242.91 41.145 238.626 40.4783C236.382 41.7827 234.087 42.9914 231.721 44.1653C236.382 45.0348 241.052 45.8262 245.721 46.5827C247.721 45.2348 249.626 43.8175 251.469 42.3479Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M177.391 79C170.435 77.1652 163.478 75.5826 156.522 74.313C151.212 76.2261 145.994 78.3363 140.87 80.6435C147.956 81.2001 155.026 81.9827 162.104 83.0783C167.183 81.5131 172.278 80.2522 177.391 79Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M188.556 76.0262C182.278 73.8783 175.982 71.8087 169.661 69.9392H169.556C165.145 71.2609 160.797 72.6841 156.513 74.2087C163.469 75.4783 170.426 77.0609 177.382 78.8957C181.121 78.0435 184.843 77.0957 188.556 76.0262Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M222.183 60.5653C216.513 59.2522 210.878 57.8783 205.209 56.4957C201.498 58.1479 197.771 59.7421 194.026 61.2783C200 62.9392 205.983 64.6261 211.974 66.2696C215.417 64.4522 218.809 62.5305 222.183 60.5653Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M188.695 76C192.694 74.8282 196.62 73.4199 200.452 71.7827C194.287 69.8 188.139 67.8 182 65.9217C177.982 67.3565 173.895 68.7218 169.695 69.9739C176.017 71.8087 182.313 73.8783 188.591 76.0609L188.695 76Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M211.974 66.2697C205.983 64.6262 200 62.9392 194.026 61.2784C190.052 62.9044 186.044 64.4523 181.965 65.9218C188.105 67.8001 194.252 69.8001 200.418 71.7827C204.348 70.1132 208.209 68.261 211.974 66.2697Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M162.173 83.0436C155.095 81.948 148.026 81.1653 140.939 80.6088C135.478 83.0841 130.11 85.832 124.834 88.8523C131.834 88.9566 138.826 89.1914 145.799 89.6523C151.027 86.9158 156.511 84.7022 162.173 83.0436V83.0436Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M607.869 92.4088C613.556 96.1305 619.261 99.8378 624.982 103.531L648.93 102.878L630.434 91.4436L607.869 92.4088Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M542.956 94.4783H542.087C546.081 98.0783 550.092 101.655 554.121 105.209C561.762 105.07 569.405 104.913 577.052 104.739C572.339 101.2 567.634 97.6436 562.991 94.0349C556.313 94.2262 549.634 94.3827 542.956 94.4783Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M630.435 91.4435L648.93 102.878C657 102.652 665.064 102.438 673.122 102.235C666.478 98.3217 659.829 94.4145 653.174 90.5131C645.591 90.8029 638.012 91.1131 630.435 91.4435Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M562.983 94.0435C567.626 97.6522 572.33 101.209 577.043 104.748L600.965 104.174C595.748 100.574 590.53 96.9537 585.313 93.3131C577.864 93.5914 570.42 93.8348 562.983 94.0435Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M585.304 93.3131C590.487 96.9653 595.704 100.586 600.956 104.174L624.974 103.53C619.258 99.8377 613.554 96.1305 607.861 92.4087C600.348 92.745 592.829 93.0464 585.304 93.3131Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M675.939 89.7305C683.325 89.5044 690.71 89.3305 698.096 89.2087C691.899 85.9623 685.681 82.7421 679.444 79.5478C672.487 79.6928 665.531 79.9014 658.574 80.1739C664.377 83.3333 670.166 86.5189 675.939 89.7305Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M658.574 80.174C665.53 79.9131 672.487 79.7044 679.443 79.5479C673.843 76.6783 668.229 73.8377 662.6 71.0261C656.009 71.2 649.417 71.4406 642.826 71.7479C648.096 74.5247 653.345 77.3334 658.574 80.174Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M637.044 81.1044C642.435 84.229 647.812 87.3653 653.174 90.5131C660.757 90.2174 668.345 89.9566 675.939 89.7305C670.142 86.5189 664.345 83.3305 658.548 80.1653C651.4 80.4378 644.232 80.7508 637.044 81.1044Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M673.122 102.235C681.168 102.026 689.211 101.829 697.252 101.643C690.156 97.6609 683.052 93.6899 675.939 89.7305C668.345 89.9508 660.756 90.2116 653.174 90.5131C659.835 94.4319 666.484 98.3391 673.122 102.235Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M365.417 92.0435C357.983 93.1478 350.574 94.4956 343.183 95.9478C342.957 97.3826 342.696 98.7652 342.391 100.035C341.769 102.54 340.997 105.005 340.078 107.417C348.07 106.27 356.07 105.174 364.087 104.261C364.676 100.917 365.088 97.544 365.322 94.1565C365.365 93.4609 365.391 92.7565 365.417 92.0435Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M388.252 89.6436C380.591 90.0697 372.991 90.9218 365.417 92.0523C365.417 92.7566 365.365 93.461 365.322 94.1653C365.088 97.5528 364.676 100.926 364.087 104.27C372.061 103.348 380.061 102.617 388.087 102.183C388.244 98.7653 388.339 95.3392 388.313 91.9218C388.305 91.174 388.278 90.4088 388.252 89.6436Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M409.713 89.4957C410.078 93.5653 410.504 97.6435 410.965 101.67C418.861 101.67 426.765 101.809 434.661 102.017C433.623 98.0812 432.62 94.1421 431.652 90.2001C424.348 89.9218 417.026 89.6522 409.713 89.4957Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M400 89.3479C396.07 89.3479 392.174 89.4262 388.261 89.6436C388.261 90.4088 388.313 91.1653 388.322 91.9218C388.322 95.3392 388.252 98.7653 388.096 102.183C392.035 101.974 395.983 101.817 399.939 101.765C403.615 101.725 407.293 101.707 410.974 101.713C410.513 97.6436 410.104 93.5653 409.722 89.5392C406.47 89.4349 403.235 89.374 400 89.3479Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M343.182 95.9565C336.069 97.3478 328.974 98.8173 321.861 100.235C320.913 102.235 319.93 104.226 318.895 106.191C318.043 107.8 317.105 109.361 316.087 110.87C324.087 109.757 332.078 108.565 340.078 107.391C340.997 104.979 341.769 102.514 342.391 100.009C342.695 98.7739 342.956 97.3913 343.182 95.9565Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M520.33 94.3913C523.721 98.0261 527.147 101.644 530.565 105.252C538.391 105.348 546.217 105.357 554.043 105.252H554.13C550.107 101.693 546.095 98.116 542.095 94.5217C534.826 94.5913 527.573 94.5392 520.33 94.3913Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M475.878 92.5739C478.043 96.3304 480.226 100.07 482.417 103.809C490.522 104.139 498.617 104.461 506.713 104.722L498.452 93.7043C490.93 93.3826 483.4 92.9913 475.878 92.5739Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M498.452 93.7044L506.713 104.722C514.661 104.971 522.612 105.148 530.565 105.252C527.148 101.644 523.722 98.0262 520.33 94.3914C513.043 94.2523 505.739 94.0087 498.452 93.7044Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M453.713 91.3391C455.278 95.1826 456.864 99.0145 458.47 102.835C466.452 103.148 474.435 103.487 482.418 103.809C480.235 100.07 478.07 96.3304 475.878 92.5739C468.487 92.1739 461.096 91.7478 453.713 91.3391Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M431.652 90.2435C432.62 94.1856 433.623 98.1247 434.661 102.061C442.603 102.264 450.539 102.522 458.469 102.835C456.869 99.0087 455.284 95.1769 453.713 91.3392C446.356 90.9392 439.003 90.574 431.652 90.2435Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M484.609 5.23481C484.061 4.78264 483.531 4.32177 483.009 3.84352L473.148 3.89569C473.591 4.39134 474.018 4.87828 474.496 5.35653L484.609 5.23481Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M559.904 26.1479C565.008 26.7479 570.113 27.3914 575.191 28.0261C572.582 26.1566 570.034 24.2783 567.365 22.4609C562.524 21.8232 557.669 21.2145 552.799 20.6348C555.252 22.4783 557.6 24.3305 559.904 26.1479Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M474.496 5.35653C474.044 4.87828 473.626 4.39133 473.148 3.89568L463.357 3.97394L464.496 5.51305L474.496 5.35653Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M495.252 5.13052C494.565 4.70443 493.878 4.26095 493.217 3.80007L483.008 3.84357C483.53 4.32183 484.06 4.78272 484.608 5.2349C488.156 5.19432 491.704 5.15951 495.252 5.13052Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M506.626 5.08702C505.653 4.69571 504.687 4.26964 503.722 3.79137H493.218C493.879 4.26094 494.566 4.66094 495.252 5.12181C499.044 5.09862 502.835 5.08702 506.626 5.08702Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M435.104 6.09572L434.321 4.22614L424.756 4.32178L425.513 6.31311L435.104 6.09572Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M425.513 6.31312L424.756 4.32178L415.148 4.42613C415.374 5.13049 415.6 5.83486 415.835 6.53922L425.513 6.31312Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M444.861 5.88702L443.991 4.14786L434.339 4.2435L435.121 6.11309L444.861 5.88702Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M464.496 5.5131L463.356 3.97396L453.669 4.04352L454.661 5.68703L464.496 5.5131Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M454.661 5.68697L453.669 4.0435L443.974 4.13046L444.843 5.86957L454.661 5.68697Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M517.8 5.08698C516.609 4.70438 515.418 4.27829 514.252 3.80873H503.722C504.687 4.28699 505.652 4.67828 506.626 5.10436C510.354 5.06378 514.078 5.058 517.8 5.08698Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M546.435 16.0783C550.948 16.5218 555.461 17.0262 559.974 17.5566C558.02 16.3566 556.035 15.2204 554.018 14.1479C549.826 13.7479 545.644 13.3885 541.47 13.0696C543.157 14.0001 544.809 15.0088 546.435 16.0783Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M537.391 11.0087C541.263 11.2 545.139 11.4377 549.017 11.7218L547.852 11.2087C546.8 10.7652 545.739 10.3392 544.669 9.95654C540.982 9.77683 537.304 9.629 533.634 9.51305C534.913 9.97392 536.156 10.4609 537.391 11.0087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M541.469 13.0696C545.643 13.3826 549.826 13.7421 554.017 14.1479C552.383 13.2841 550.727 12.4841 549.052 11.7479C545.168 11.4696 541.293 11.2319 537.426 11.0348C538.8 11.6522 540.139 12.3305 541.469 13.0696Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M552.843 20.6349C557.701 21.2146 562.556 21.8233 567.408 22.461C564.974 20.7218 562.495 19.1131 559.974 17.5566C555.461 17.0262 550.948 16.5219 546.435 16.0784C548.617 17.5132 550.748 19.0523 552.843 20.6349Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M523.087 6.5565C526.678 6.49563 530.278 6.44345 533.878 6.43475C532.417 5.99128 530.965 5.5652 529.53 5.08695C525.623 5.08695 521.719 5.08695 517.817 5.08695C519.548 5.60868 521.313 6.09564 523.087 6.5565Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M533.669 9.53914C537.339 9.64929 541.017 9.79712 544.704 9.98262C543.376 9.48408 542.04 9.01453 540.695 8.57396C537.02 8.45222 533.347 8.35946 529.678 8.2957C531.008 8.6696 532.347 9.07828 533.669 9.53914Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M529.513 5.08697C528.191 4.66958 526.869 4.23479 525.547 3.78261H514.243C515.408 4.25218 516.6 4.65219 517.791 5.06089C521.704 5.07828 525.611 5.08697 529.513 5.08697Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M527.826 7.79133C531.165 7.72177 534.513 7.6812 537.87 7.66961L533.913 6.43483C530.313 6.43483 526.713 6.49571 523.122 6.55658L527.826 7.79133Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M529.678 8.29571C533.348 8.35368 537.02 8.44643 540.695 8.57397C539.765 8.26093 538.826 7.96528 537.895 7.70442C534.539 7.70442 531.191 7.74498 527.852 7.82614L529.678 8.29571Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M406.017 6.77399C405.817 6.02617 405.626 5.27834 405.443 4.53921L395.635 4.64355C395.748 5.43485 395.869 6.21747 396.009 7.01747L406.017 6.77399Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M286.739 5.50435H276.582C275.382 6.58262 274.206 7.67827 273.052 8.79132H283.556C284.6 7.69567 285.661 6.60001 286.739 5.50435Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M294 8.8C294.922 7.69855 295.861 6.5942 296.817 5.48695H286.739C285.661 6.5826 284.6 7.69566 283.556 8.8087C287.035 8.8203 290.516 8.81739 294 8.8Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M304.452 8.73045C305.223 7.6464 306.02 6.55366 306.844 5.45222H296.817C295.861 6.55946 294.922 7.6638 294 8.76524C297.49 8.77104 300.974 8.75944 304.452 8.73045Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M283.557 8.82613H273.052C271.765 10.0783 270.487 11.3392 269.235 12.6087C272.835 12.6435 276.435 12.6667 280.035 12.6783C281.171 11.3856 282.345 10.1015 283.557 8.82613Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M267.696 26.6087C263.875 26.4985 260.052 26.3478 256.226 26.1565C254.707 27.7102 253.157 29.258 251.574 30.8L250.913 31.4174C254.687 31.7884 258.455 32.1304 262.217 32.4435C264.096 30.5391 265.904 28.5652 267.696 26.6087Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M314.931 8.63484C315.557 7.5479 316.2 6.46967 316.861 5.39142L306.844 5.45229C306.026 6.54793 305.229 7.64066 304.452 8.7305L314.931 8.63484Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M280 12.6523C276.4 12.6523 272.8 12.6291 269.2 12.5827C267.826 13.9856 266.461 15.3943 265.104 16.8088C268.791 16.8841 272.475 16.9421 276.156 16.9827C277.449 15.5392 278.73 14.0957 280 12.6523Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M272.087 21.6609C268.331 21.5913 264.563 21.4957 260.783 21.3739C259.261 22.9739 257.739 24.5652 256.192 26.1566C260.018 26.3363 263.841 26.487 267.661 26.6087C269.157 24.9739 270.632 23.3246 272.087 21.6609Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M276.191 16.9826C272.51 16.9479 268.826 16.8899 265.139 16.8087L260.791 21.3739C264.548 21.4957 268.316 21.5913 272.095 21.6609C273.452 20.1044 274.782 18.5391 276.191 16.9826Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M375.887 7.50441C375.887 6.63484 375.939 5.76528 375.991 4.89571L366.113 5.00005C365.965 5.9131 365.826 6.83485 365.704 7.7566L375.887 7.50441Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M396.009 7.01746C395.87 6.21745 395.748 5.43483 395.635 4.64352L385.826 4.75657C385.826 5.58266 385.913 6.41745 385.974 7.26094L396.009 7.01746Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M415.835 6.5392C415.6 5.83485 415.374 5.13049 415.148 4.42614L405.443 4.53919C405.626 5.27832 405.817 6.02615 406.017 6.77397L415.835 6.5392Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M325.13 8.50439C325.652 7.44351 326.191 6.38264 326.748 5.33046L316.861 5.39134C316.2 6.46961 315.556 7.54786 314.93 8.63482L325.13 8.50439Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M262.234 32.4435C258.46 32.1305 254.692 31.7885 250.93 31.4175C249.109 33.1229 247.19 34.7198 245.182 36.2001C249.113 36.7044 253.04 37.174 256.965 37.6087C258.774 35.9334 260.53 34.2116 262.234 32.4435Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M385.974 7.26092C385.913 6.39135 385.861 5.58264 385.826 4.75655L375.991 4.86959C375.939 5.73917 375.913 6.60874 375.887 7.47832L385.974 7.26092Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M345.495 8.15655C345.8 7.15655 346.121 6.15654 346.46 5.16523L336.582 5.25219C336.13 6.27828 335.713 7.31306 335.26 8.33916C338.678 8.28698 342.087 8.21742 345.495 8.15655Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M335.261 8.33911C335.687 7.31303 336.13 6.27826 336.583 5.25218L326.748 5.33044C326.191 6.38261 325.652 7.44346 325.13 8.50433L335.261 8.33911Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M365.704 7.69576C365.826 6.77403 365.965 5.85228 366.113 4.93923L356.278 5.0349C356.026 5.99142 355.8 6.94795 355.574 7.92186L365.704 7.69576Z"
      stroke="white"
      strokeMiterlimit="2"
    />
    <path
      d="M355.574 7.9565C355.8 6.98258 356.026 6.02608 356.278 5.06956L346.461 5.1652C346.122 6.1565 345.8 7.15651 345.496 8.15651L355.574 7.9565Z"
      stroke="white"
      strokeMiterlimit="2"
    />
  </svg>
);
